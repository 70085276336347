import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {MButton} from "../components/MButton";
import * as React from "react";
import MStatusPlates from "../components/MStatusPlates";
import MSelect from "../components/MSelect";
import MDatePicker from "../components/MDatePicker";
import MTextField from "../components/MTextField";
import {formatStudyType} from "../utils/utils";
import {Enrollment, reSubmitStudy, uploadPDFReport} from "../api/study";
import MRadio from "../components/MRadio";

interface StudyInfoProps {
    study: any;
}

const fileOperationOptions = [
    {
        value: "true",
        label: "Save without changes"
    },
    {
        value: "false",
        label: "Process(remove cover page, add meta data etc.)"
    },
]

const reSubmissionOptions = [
    {
        value: "re-submit",
        label: "Re-submit. Once processed, generated report will be added to Mawi System"
    },
    {
        value: "duplicate",
        label: "Duplicate. Re-uploaded record will be ignored by Mawi system"
    }
]

export const StudyInfoAdmin = (studData: StudyInfoProps) => {
    //alert(newIdPatient);
    //console.log(newIdPatient);
    const [study, setStudy] = useState<any>(null);
    const [PDFBase64,setPDFBas64] = useState<any>(null);
    const [isFileSelected,setIsFileSelected] = useState<boolean>(false);
    const [isSaveOriginal,setIsSaveOriginal] = useState("true");
    const [reSubmitOption,setReSubmitOption] = useState(reSubmissionOptions[0].value);
    const [isReSubmited, setIsResubmited] = useState(false);

    useEffect(() => {
        setStudy(studData.study);
    }, [studData]);

    const convertBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {

                let encoded = fileReader.result?.toString().replace(/^data:(.*,)?/, '');
                if(encoded){
                    if ((encoded?.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }

            }
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            }
        })
    }
    const handleFileRead = async (event:any) => {

        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        // console.log(base64)
        setPDFBas64(base64);
        setIsFileSelected(true);
    }

    const uploadNewPDF = async () => {
        let id_track = null;
        if(study.id_track){
            id_track = study.id_track
        } else{
            id_track = study.measurements[0].id_track
        }

        if(id_track !==null){
            let data = {
                "file_base64": PDFBase64,
                "exam_reporting_request_id" : id_track,
                "is_save_original": isSaveOriginal == "true"
            }
            await uploadPDFReport(data)
            setIsFileSelected(false)
            window.location.reload();
        }
    }

    const sendStudyReSubmit = async () => {
        let data = {
            id_study : study.id,
            is_update_database : reSubmitOption == reSubmissionOptions[0].value
        }
        await reSubmitStudy(data)
        setIsResubmited(true);
    }

    return (
        <>
            <Box justifyContent="space-between" width="100%" mb={4}>
                <Box display="flex" alignItems="center" mb={4}>
                    <Typography variant="h4"
                                fontStyle={{"color": "#4F4949"}}>
                        {"Study details "}
                        <MStatusPlates value={study ? study?.status : undefined}/>
                    </Typography>
                </Box>
                <Box m="dense" display="flex">
                    <Box width="45%">
                        <Typography variant="h5">Report</Typography>
                        <br/>
                        <Typography variant="body1" fontStyle={{"color": "grey"}}>
                            {
                                study?.report ? (
                                    <Box
                                        sx={{textDecoration: 'underline', fontWeight: '600'}}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(study.report, "_blank");
                                        }}
                                    >
                                        View Final Report
                                    </Box>
                                ) : '—'
                            }
                        </Typography>
                        <br/>
                        <Typography variant="h5">Enrollment type</Typography>
                        <br/>
                        <Typography variant="body1" fontStyle={{"color": "grey"}}>{study?.enrollment}</Typography>
                        <br/>
                        <Typography variant="h5">Study type</Typography>
                        <br/>
                        <Typography variant="body1" fontStyle={{"color": "grey"}}>{formatStudyType(study?.study_type)}</Typography>
                        <br/>
                        <Typography variant="h5">Study language</Typography>
                        <br/>
                        <Typography variant="body1" fontStyle={{"color": "grey"}}>{study?.study_language}</Typography>
                    </Box>
                    <Box width="45%" ml="10%">
                        <Typography variant="h5">Replace PDF report</Typography>
                        <br/>
                        <Typography variant="body1" fontStyle={{"color": "grey"}}>
                            NOTE! This functionality works with the reports generated in AMPS only
                        </Typography>
                        <br/>
                        <MTextField
                            id="originalFileName"
                            type="file"
                            inputProps={{ accept:  '.pdf' }}
                            required
                            label=""
                            name="originalFileName"
                            size="medium"
                            variant="standard"
                            onChange={e => handleFileRead(e)}
                        />
                        <br/>
                        <br/>
                        {isFileSelected && <>
                                <MRadio
                                    label="Operation type"
                                    options={fileOperationOptions}
                                    value={isSaveOriginal}
                                    onChange={(e, val) => setIsSaveOriginal(val)}
                                />
                                <br/>
                                <br/>
                                <MButton sx={{width:"100%"}}
                                         onClick={()=> uploadNewPDF()}
                                         >
                                    Upload PDF
                                </MButton>
                            </>

                        }
                        <br/>
                        <br/>
                        {!isReSubmited && <>
                            <Typography variant="h5">Re-submit study for analysis</Typography>
                            <br/>
                            <MRadio
                                label="Re-upload type"
                                options={reSubmissionOptions}
                                value={reSubmitOption}
                                onChange={(e, val) => setReSubmitOption(val)}
                            />
                            <br/>
                            <br/>
                            <MButton sx={{width:"100%"}} onClick={()=> sendStudyReSubmit()}>
                                Re-submit record
                            </MButton>
                        </>}





                    </Box>
                </Box>
            </Box>
        </>
    )
}