import {useNavigate, useParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import {useCallback, useEffect, useState} from "react";
import {getPatient} from "../api/patient";
import {getStudyAdmin} from "../api/study";
import {PatientInfo} from "../containers/PatientInfo";
import {MeasurementInfo} from "../containers/MeasurementInfo";
import {StudyInfoAdmin} from "../containers/StudyInfoAdmin";
import MStatusPlates from "../components/MStatusPlates";


export const AdminStudy = () => {
    const {idStudy} = useParams();
    const [study, setStudy] = useState<any>(null);
    const [idUnique,setIdUnique] = useState("");
    const navigate = useNavigate();

    const getStudyData = useCallback(async () => {
        if (idStudy != null) {
            let study = await getStudyAdmin(idStudy);
            setStudy(study.data);
            setIdUnique(study.data.id_unique);
        }

    },[])

    useEffect(() => {
        getStudyData();
    }, [getStudyData])


    return (
        <>
            <PageHeader title={"Study "+idStudy} actions={
                [{onClick: () => {navigate(-1)}, name: "Close"}]
            }/>
            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                <StudyInfoAdmin study={study} />
                <PatientInfo newIdPatient={idUnique}/>
                {study?.measurements &&
                    study.measurements.map((item:any,i:number) => <MeasurementInfo newItem={item}/>)}
            </Paper>
        </>
    )
}