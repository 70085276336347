import {Checkbox, FormControlLabel} from "@mui/material";
import {OutlinedTextFieldProps} from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {MButtonProps} from "./MButton";
import {ChangeEvent} from "react";

type TextFieldOmittedFields = 'variant' | 'label';

export interface MCheckboxProps {
    label: string;
    value?: Boolean | false;

    checked: boolean;
    onChange?: (event : ChangeEvent,checked: Boolean | null) => void;

    disabled?: boolean | false;

}

export const MCheckbox = (props: MCheckboxProps) => {
    const {label, value = false, disabled=false, checked = false, onChange} = props;



    return (
        <>
            <FormControlLabel
                control={<Checkbox value={value} disabled={disabled} checked={checked}  onChange={onChange} />}
                label={label}/>
        </>
    );
}

export default MCheckbox;