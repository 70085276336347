import moment from "moment";
import {config} from "../config";
import {networkLayer} from './networkLayer';
import {getValueFromLocalStorage} from './auth';

export type Gender = 'male' | 'female';

export interface PatientInterface {
  first_name: string;
  last_name: string;
  gender: Gender;
  birthday: Date | string;
}

export const createPatient = async (values: PatientInterface, insurance: any,clinical_reasons: any) => {
  const stuffProfile = await getValueFromLocalStorage('staff_profile');

  return await networkLayer('post', `${config.usersUrls}/dashboard/invite_rpm_user/`, {
    profile: {
      first_name: values.first_name,
      last_name: values.last_name,
      gender: values.gender,
      birthday: moment(values.birthday).format("YYYY-MM-DD"),
      id_company: stuffProfile.id_company,
      id_department: stuffProfile.id_department,
      insurance_information_data : insurance,
      clinical_reasons_data : clinical_reasons
    }
  });
}


export const updatePatient = async (values: any) => {
  let newValues = {
    "id_unique" : values.unique_id,
    "first_name" : values.first_name,
    "last_name" : values.last_name,
    "gender" : values.gender,
    "birthday" : values.birthday
  }
  return await networkLayer('post', `${config.usersUrls}/dashboard/update_patient/`, newValues);
}

export const getPatient = async (id_unique:string) => {
  return await networkLayer('post', `${config.usersUrls}/dashboard/user/`, {
    id_unique:id_unique
  });
}