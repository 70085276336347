import {useCallback, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {MTextField} from "../components/MTextField";
import {MSelect} from "../components/MSelect";
import FormSection from "./FormSection";
import {MButton} from "../components/MButton";
import {useNavigate} from "react-router-dom";
import {validateEmail, validateRequiredField} from "../utils/validator";
import * as React from "react";
import {CircularProgress, InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {createStuffAccount, getStaffProfile, updateStaffProfile} from "../api/clinic_staff";
import ReactJson from "react-json-view";
import Typography from "@mui/material/Typography";
import {PDFDownloadLink } from '@react-pdf/renderer'
import CredentialPDFView from "../components/СredentialsPDF";
import MModal from "../components/MModal";
import {listIdOrganizationExternals} from "../api/id_organization_external";

interface ClientsFormInterface {
    name?: string,
    clinicId?: string,
    staffProfileId?: string,
    action : "create" | "edit" | "view"
}

const roles = [
    {
        value : "clinician",
        label : "Clinician"
    }
]



export const ClinicianForm = (props: ClientsFormInterface) => {
    const {name,clinicId, action, staffProfileId} = props;
    const navigate = useNavigate();

    // const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
    const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);

    // Credentials section
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<string>('');

    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [showPassword, setShowPassword] = React.useState(false);

    const [isPasswordChanged, setIsPasswordChanged] = React.useState(false);
    const [isEmailChanged, setIsEmailChanged] = React.useState(false);


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event:any) => {event.preventDefault();};

    // Account info section
    const [accountName, setAccountName] = useState<string>("");
    const [accountNameError, setAccountNameError] = useState<string>('');

    const [role, setRole] = useState(roles[0].value)

    const [isAccountInfoChanged, setIsAccountInfoChanged] = React.useState(false);

    const [isInProgress, setIsInProgress] = useState<boolean>(false);


    const getClientAccount = useCallback(async () => {
        if(action == "edit"){
            let data = await getStaffProfile(staffProfileId);

            console.log(data);
            setEmail(data.email);
            setPassword("");
            setAccountName(data.name);
            setRole(data.role);
        }
    },[]);
    useEffect(() => {
        getClientAccount();
    },[getClientAccount]);


    const formValueChangeHandler = (field: 'email' | 'password' | "accountName" ,
                                    value: string) => {
        if (field === 'email') {
            if (emailError) {
                setEmailError('')
            }
            setIsEmailChanged(true);
            return setEmail(value);
        }

        if (field === 'password') {
            if (passwordError) {
                setPasswordError('')
            }
            setIsPasswordChanged(true);
            return setPassword(value);
        }
        if (field === 'accountName') {
            if (accountNameError) {
                setAccountNameError('')
            }
            setIsAccountInfoChanged(true);
            return setAccountName(value);
        }
    }

    const prepareAccountData = () => {
        return {
            email : email,
            password : password,
            staffprofile_set :
                {
                    name : accountName,
                    role : role,
                    stafforganizationconnection_set :
                        {
                            unit : clinicId
                        }

                }
        }
    }

    const prepareUpdateData = (action:string) => {
        switch(action){
            case "change_email":
                return {
                    new_email : email,
                    action : action
                }
            case "change_password":
                return {
                    new_password : password,
                    action : action
                }
            case "change_account_info":
                return {
                    action : "change_meta",
                    name : accountName,
                    role : role
                }
        }
    }



    const onSubmitCreate = async () => {
        const emailMessage = validateEmail(email);
        const accountNameMessage = validateRequiredField('Account Name', accountName);
        const passwordMessage = validateRequiredField('Password', password);


        if (emailMessage || passwordMessage  || accountNameMessage ) {
            setEmailError(emailMessage);
            setPasswordError(passwordMessage);
            setAccountNameError(accountNameMessage);
            return;
        }

        setIsInProgress(!isInProgress);
        let data = prepareAccountData();
        await createStuffAccount(data);
        setIsPopUpOpen(true);
    }

    const onSubmitEmailUpdate = async () => {
        const emailMessage = validateEmail(email);

        if (emailMessage) {
            setEmailError(emailMessage);
            return;
        }

        let data = prepareUpdateData("change_email");
        if (staffProfileId != null) {
            await updateStaffProfile(staffProfileId, data);
        }
        setIsEmailChanged(false);
    }

    const onSubmitPasswordChange = async () => {
        const passwordMessage = validateRequiredField('Password', password);
        if (passwordMessage) {
            setPasswordError(passwordMessage);
            return;
        }

        let data = prepareUpdateData("change_password");
        if (staffProfileId != null) {
            await updateStaffProfile(staffProfileId, data);
        }
        setIsPasswordChanged(false);
    }

    const onSubmitAccountInfoChange = async () => {
        const accountNameMessage = validateRequiredField('Account Name', accountName);

        if (accountNameMessage) {
            setAccountNameError(accountNameMessage);
            return;
        }
        let data = prepareUpdateData("change_account_info");
        if (staffProfileId != null) {
            await updateStaffProfile(staffProfileId, data);
        }
        setIsAccountInfoChanged(false);
    }


    return (
        <>
            <Box sx={{maxWidth: 750, margin: "0 auto"}}>
                <FormSection sectionName="Credentials">
                    <>
                        <MTextField
                            label="Email"
                            value={email}
                            error={!!emailError}
                            helperText={emailError}
                            onChange={(e) => {formValueChangeHandler('email', e.target.value)}}
                        />
                        {isEmailChanged && action == "edit" &&
                            <MButton variant="outlined"
                                     color="info"
                                     onClick={() => onSubmitEmailUpdate()}
                                     sx={{color:"error", width:"75%"}}>Update email</MButton>
                        }
                        <MTextField
                            label="Password"
                            value={password}
                            type={(showPassword) ? "text" : "password"}
                            error={!!passwordError}
                            helperText={passwordError}
                            onChange={(e) => {formValueChangeHandler('password', e.target.value)}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {isPasswordChanged && action == "edit" &&
                            <MButton variant="outlined"
                                     color="info"
                                     onClick={() => onSubmitPasswordChange()}
                                     sx={{color:"error", width:"75%"}}>Update password</MButton>
                        }
                    </>
                </FormSection>
                <FormSection sectionName="Account info">
                    <>
                        <MTextField label="Client" value={name} disabled={true} onChange={() => {}}/>
                        <MSelect
                            label="Role"
                            value={role}
                            options={roles}
                            onChange={(e) => setRole(e.target.value)}
                        />
                        <MTextField label="Account Name"
                                    value={accountName}
                                    error={!!accountNameError}
                                    helperText={accountNameError}
                                    onChange={(e) => {formValueChangeHandler("accountName",e.target.value);}}
                        />
                        {isAccountInfoChanged && action == "edit" &&
                            <MButton variant="outlined"
                                     color="info"
                                     onClick={() => onSubmitAccountInfoChange()}
                                     sx={{color:"error", width:"75%"}}>Update account info</MButton>
                        }
                    </>
                </FormSection>
                {action == "create" &&
                    <Box
                        py={3.5}
                        textAlign="center"
                        display="flex"
                    >
                        <Box marginLeft="45%">
                            <MButton variant="outlined"
                                     color="error"
                                     onClick={() => navigate(-1)}
                                     sx={{color:"error"}}>Cancel</MButton>
                        </Box>
                        <Box marginLeft="1%">
                            <MButton onClick={onSubmitCreate} disabled={isInProgress}>Create account</MButton>
                        </Box>
                        {isInProgress && <CircularProgress hidden={!isInProgress} />}

                    </Box>
                }

            </Box>



            <MModal
                open={isPopUpOpen}
                handleClose={() => {
                    // setIsPopUpOpen(false)
                    navigate(-1);
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 10}} paragraph={true}>
                        Account creation
                    </Typography>
                    <Typography variant="body1" sx={{mb: 5}}>
                        Account was created successfully
                    </Typography>
                    <Box sx={{textAlign: "center",mb: 5}}>
                        <div>
                            <PDFDownloadLink document={
                                <CredentialPDFView clinic_name={name}
                                                   account_name={accountName}
                                                   email={email}
                                                   password={password}
                                />}
                                             fileName={name+"_"+email+"_credentials.pdf"}>
                                {({ blob, url, loading, error }) =>
                                    loading ? 'Loading document...' : 'Download PDF with credentials '
                                }
                            </PDFDownloadLink>
                        </div>
                    </Box>
                    <Box sx={{textAlign: "center"}}>
                        <MButton onClick={() => {navigate(-1)}}>Continue</MButton>
                    </Box>

                </Box>
            </MModal>
        </>
    );
};

export default ClinicianForm;