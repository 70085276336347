import {useState, useEffect, useCallback} from "react";
import Box from "@mui/material/Box";
import PageHeader from "../containers/PageHeader";
import Typography from "@mui/material/Typography";
import {addCluster, getClustersList} from "../api/cluster";
import {MModal} from "../components/MModal";
import {MSelect} from "../components/MSelect";
import {MTextField} from "../components/MTextField";
import {MButton} from "../components/MButton";
import MList from "../components/MList";
import {useNavigate, useParams} from 'react-router-dom';

// import {renderAccordionCompanies} from "../components/MAccordion";
// import {addDepartment, getDepartmentList} from "../api/department";
// import {renderAccordionHeaderCompaniesList} from "../components/MAccordionHeader";
// import {renderAccordionDetailsCompaniesList} from "../components/MAccordionDetails";
import {baseUrls} from "../routes";
import MRadio from "../components/MRadio";
import {CircularProgress, Paper} from "@mui/material";
import * as React from "react";
import MCheckbox from "../components/MCheckbox";
import UnitsListGrid from "../containers/UnitsListGrid";
import {getRegion} from "../api/region";




export const Region = () => {
    const {regionId} = useParams();
    const [regionDetails, setRegionDetails] = useState(null);

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [clustersList, setClustersList] = useState<any[]>([]);

    const [newClusterName, setNewClusterName] = useState("");
    const [isProcessing,setIsProcessing] = useState(false);

    const fetchRegionDetails = useCallback( async () => {
        if (regionId != null) {
            const regionDetails = await getRegion(regionId);
            setRegionDetails(regionDetails);

            const clustersList = await getClustersList(regionId);
            clustersList.sort((a:any, b:any) => b.departments_number - a.departments_number);
            setClustersList(clustersList);
        }
    }, [regionId])

    useEffect(() => {
        fetchRegionDetails()
    }, [fetchRegionDetails]);


    const onEntityCreation = async () => {
        setIsProcessing(true);

        let data = {
            "name" : newClusterName,
            "region": regionId
        }
        await addCluster(data);
        window.location.reload();

    }


    const renderModalContent = () => (
        <Box p={3}>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant="h3">Add new cluster</Typography>
            </Box>
            <Box mt={6} width={320}>

            </Box>
            <Box mt={1.5} width={320}>
                <MTextField
                    label="New Cluster name"
                    onChange={(e) => setNewClusterName(e.target.value)}
                />
            </Box>
            <Box
                mt={8}
                sx={{
                    "& .MuiButton-root:not(:last-child)": {marginRight: 2},
                    textAlign: 'center'
                }}
            >
                <MButton onClick={handleClose} color={"inherit"}>Cancel</MButton>
                <MButton onClick={onEntityCreation} color={"primary"}>Confirm</MButton>
                {isProcessing && <CircularProgress hidden={!isProcessing} />}
            </Box>
        </Box>
    )

    return (
        <>
            <MModal open={open} handleClose={handleClose} width={480} height="auto">
                {renderModalContent()}
            </MModal>
            <PageHeader title={`${(!regionDetails ? "??" : regionDetails["name"])}`}
                        subTitle="region"
                        actions={[
                {onClick: handleOpen, name: "Add Cluster"}]}/>
            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                <UnitsListGrid type="cluster" data={clustersList}/>
            </Paper>
        </>
    );
}
export default Region;