import {useCallback, useEffect, useState} from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {MButton} from "../components/MButton";
import {MTextField} from "../components/MTextField";
import MDatePicker from "../components/MDatePicker";
import MDateTimePicker from "../components/MDateTimePicker";
import ReactJson from "react-json-view";
import InputLabel from "@mui/material/InputLabel";
import {getPatient, updatePatient} from "../api/patient";
import MSelect from "../components/MSelect";
import {updateStudyMeasurement} from "../api/study";

const genderOptions = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
];

const serviceProviders = [
    {
        value: 'cardio.ai',
        label: 'Cardio.ai',
    },
    {
        value: 'amps',
        label: 'AMPS',
    }
]


export const MeasurementInfo = (newItem: any) => {
    const [defaultItem, setDefaultItem] = useState<any>(newItem.newItem);
    const [item, setItem] = useState<any>(newItem.newItem);
    const [isEdit, setIsEdit] = useState<boolean>(false);


    const postUpdateMeasurement = async () => {
        let measurement_data = {
            "id" : item.id,
            "analysis_configuration" : (item.analysis_platform == "cardio.ai") ? item.analysis_configuration : null,
            "date_start" : item.date_start,
            "analysis_platform" : item.analysis_platform,
            "analysis_token" : (item.analysis_platform == "cardio.ai") ? item.analysisToken : null,
            "report_email" : item.report_email,
            "id_unique" : item.unique_id,
            "ordering_physician_full_name" : item.ordering_physician_full_name
        }
        let data = await updateStudyMeasurement(measurement_data);
        console.log(data);
        setIsEdit(false);

    }

    const handleMeasurementChange = (keyToUpdate: string, newValue: string | object) => {
        let newDict = {...item};
        newDict[keyToUpdate] = newValue;
        setItem(newDict);
    };

    return (
        <>
            <Box justifyContent="space-between" width="100%"  mt={6}>
                <Box mt={6} mb={3}>
                    <Box display="flex" alignItems="center" mb={6}>
                        <Typography variant="h4"
                                    fontStyle={{"color": "#4F4949","fontWeight": "bold"}}>
                            {"Device S/N:  "+item.device_serial_number}
                        </Typography>
                        <br/>
                        {!isEdit &&
                            <MButton variant="text"
                                     onClick={() => {
                                         setIsEdit(true);
                                     }}>
                                Edit
                            </MButton>
                        }
                        {isEdit &&
                            <>
                                <MButton variant="text"
                                         hidden={!isEdit}
                                         color="error"
                                         onClick={() => {
                                             setIsEdit(false);
                                             setItem(defaultItem);
                                             // getPatientData();

                                         }}>
                                    Cancel
                                </MButton>
                                <MButton variant="text"
                                         hidden={!isEdit}
                                         onClick={() => {
                                             postUpdateMeasurement();
                                             setIsEdit(false);
                                         }}>
                                    Save
                                </MButton>
                            </>
                        }

                    </Box>
                    <Box m="dense" display="flex">
                        <Box width="45%">
                            <MTextField label="Report email"
                                        value={item?.report_email}
                                        variant="standard"
                                        disabled={!isEdit}
                                        onChange={(e) => {
                                            handleMeasurementChange("report_email", e.target.value);
                                        }}

                            />
                            <br/>
                            <br/>
                            <MTextField label="Ordering physician"
                                        value={item?.ordering_physician_full_name}
                                        variant="standard"
                                        onChange={(e) => {
                                            handleMeasurementChange("ordering_physician_full_name", e.target.value);
                                        }}
                                        disabled={!isEdit}/>
                            <br/>
                            <br/>
                            <MDateTimePicker label="Date Study Started"
                                             value={item?.date_start}
                                             variant="standard"
                                             disabled={!isEdit}
                                             onChange={(e) => {
                                                 if(e !== null){
                                                     handleMeasurementChange("date_start", e.toISOString());
                                                 }
                                             }}
                                             />
                            <br/>
                            <br/>
                            <MDateTimePicker label="Date Measurement uploaded"
                                             value={item?.date_upload}
                                             variant="standard"
                                             disabled={true}
                                             onChange={(e) => {
                                                 if(e !== null){
                                                     handleMeasurementChange("date_upload", e.toISOString());
                                                 }
                                             }}
                            />
                            <br/>
                            <br/>
                            <MDateTimePicker label="Date Study finished"
                                             value={item?.date_finished}
                                             variant="standard"
                                             disabled={true}
                                             onChange={(e) => {
                                                 if(e !== null){
                                                     handleMeasurementChange("date_finished", e.toISOString());
                                                 }
                                             }}


                            />
                        </Box>
                        <Box width="45%" ml="10%">
                            <MSelect label="Analysis platform"
                                        value={item?.analysis_platform}
                                        options={serviceProviders}
                                        disabled={!isEdit}
                                        onChange={(e) => {
                                            handleMeasurementChange("analysis_platform", e.target.value);
                                        }}
                                        variant="standard"/>
                            <br/>
                            <br/>
                            <MTextField label="ID Track(identifier in analysis software)"
                                        value={item['id_track']}
                                        disabled={true}
                                        onChange={(e) => {
                                            handleMeasurementChange("id_track", e.target.value);
                                        }}
                                        variant="standard"/>
                            <br/>
                            <br/>
                            {item['analysis_platform'] === "cardio.ai" && (
                                <>
                                    <br/>
                                    <br/>
                                    <MTextField label="Analysis token"
                                                value={item?.analysis_token}
                                                variant="standard"
                                                disabled={!isEdit}
                                                onChange={(e) => {
                                                    handleMeasurementChange("analysis_token", e.target.value);
                                                }}
                                    />
                                    <MTextField label="URL Track(link to analysis software"
                                                value={item?.url_track}
                                                variant="standard"
                                                disabled={true}
                                                onChange={(e) => {
                                                    handleMeasurementChange("url_track", e.target.value);
                                                }}
                                    />
                                    <br/>
                                    <br/>
                                    <Typography variant="h6">
                                        <InputLabel>Analysis configuration</InputLabel>
                                    </Typography>
                                    <ReactJson
                                        src={(item['analysis_configuration'] === null) ? {} : item['analysis_configuration']}
                                        onEdit={(e) => {
                                            console.log(item['analysis_configuration']);
                                            handleMeasurementChange("analysis_configuration", e.updated_src);
                                            console.log(item['analysis_configuration']);
                                        }}/>
                                </>
                            )}
                        </Box>
                    </Box>
                    <Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}