import "@fontsource/inter";
import { AuthProvider } from "./context/useAuth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router} from "react-router-dom";
import { AppRoutes } from "./routes";
import {theme} from "./theme";
import Layout from "./containers/Layout";
import * as Sentry from "@sentry/react";
import { config } from "./config";

// Sentry.init({
//     dsn: config.sentry_dsn,
//     integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//     // Performance Monitoring
//     tracesSampleRate: 0.33, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        hideProgressBar
      />
      <Router>
        <AuthProvider>
          <Layout>
            <AppRoutes />
          </Layout>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}



export default App;
