import {useState} from "react";
import {Alert, AlertColor, Box, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Divider from "@mui/material/Divider";
import {MTextField} from "../components/MTextField";
import {MButton} from "../components/MButton";
import MTimePicker from "../components/MTimePicker";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {dateFromString} from "../utils/utils";
import Button from "@mui/material/Button";
import {adminDisableConnection, adminUpdateConnection} from "../api/device";

const MAX_ECG_REMINDERS = 5;
const ecgReminder = {value: new Date(new Date().setHours(10,0))}

const  getECGReminders = (ecgReminders : []) => {
    if (ecgReminders  ==  null){return []}
    let newReminders = Array<{value: Date}>();
    ecgReminders.map((value:string,key) => (newReminders.push({value:dateFromString(value)})))
    return newReminders
}

const getConnectionInfo = (connection: any, key:string) => {
    if(connection == null){
        return null;
    }
    return connection[key]
}

export const DeviceInfo = (newItem:any) => {
    console.log(newItem.newItem);
    const [item,setItem] = useState<any>(newItem.newItem);

    const [wifiNetwork,setWiFiNetwork] = useState<any>(getConnectionInfo(item.connection,"wifi_login"));
    const [wifiPassword,setWiFiPassword] = useState<any>(getConnectionInfo(item.connection,"wifi_password"));
    const [ppgFrequency,setPpgFrequency] = useState<any>(getConnectionInfo(item.connection,"ppg_frequency"));
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [idUnique, setIdUnique] = useState<boolean>(false);
    const [ECGDataCollectionFrequency, setECGDataCollectionFrequency] = useState<{value: Date}[]>(getECGReminders(getConnectionInfo(item.connection,"ecg_frequency")));
    const [ECGDefaultDataCollectionFrequency, setECGDefaultDataCollectionFrequency] = useState<{value: Date}[]>(getConnectionInfo(item.connection,"ecg_frequency"));

    const [isShowSnackbar,setIsShowSnackbar]  = useState<boolean>(false);
    const [textSnackbar,setTextSnackbar] = useState<string>("");
    const [typeSnackbar,setTypeSnackbar] = useState<AlertColor>("success");


    const saveDeviceConnection = async() =>{
        setIsEdit(false)
        await setTextSnackbar("Updating device connection")
        await setIsShowSnackbar(true)
        await setTypeSnackbar("info")
        await setIsEdit(false)

        let reminders: any = [];
        for (const x of ECGDataCollectionFrequency) {
            reminders.push(x['value'].getHours().toString() + ":" + x['value'].getUTCMinutes().toString());
        }
        if(reminders.length==0){
            reminders=null;
        }
        let data = {
            "wifi_login" : wifiNetwork,
            "wifi_password" : wifiPassword,
            "ppg_frequency" : ppgFrequency,
            "ecg_frequency" : reminders
        }
        await adminUpdateConnection(item.serial_number,data)

        await setTextSnackbar("Device connection updated")
        await setIsShowSnackbar(true)
        await setTypeSnackbar("success")

    }

    const disableDeviceConnection = async() => {
        await setTextSnackbar("Disconnecting device")
        await setIsShowSnackbar(true)
        await setTypeSnackbar("info")
        await setIsEdit(false)

        await adminDisableConnection(item.serial_number,{})

        await setTextSnackbar("Device disconnected")
        await setIsShowSnackbar(true)
        await setTypeSnackbar("success")
    }



    const getConnection = () => {
        return <Box >
            <br/>
            <Box display="flex" alignItems="center" paddingLeft={3}>
                    <Typography variant="body2" fontStyle={{"color":"#4F4949"}}><i>Connection information</i></Typography>
                    <MButton variant="text" onClick={() => {
                        setIsEdit(true);
                        if(ECGDataCollectionFrequency.length==0){
                            setECGDataCollectionFrequency([ecgReminder])
                        }
                    }}>
                        Edit
                    </MButton>
                <Typography variant="body2" ml="55%" fontStyle={{"color":"#4F4949"}}><i>Available actions</i></Typography>
            </Box>

            <Box display='flex'>
                <Box width="30%"  justifyContent="space-between" padding={3}>
                <MTextField
                    label="WiFi Network"
                    variant="standard"
                    value={wifiNetwork}
                    disabled={!isEdit}
                    onChange={(e) => {setWiFiNetwork(e.target.value);}}
                />
                <br/><br/>
                <MTextField
                    label="WiFi Password"
                    value={wifiPassword}
                    disabled={!isEdit}
                    variant="standard"
                    onChange={(e) => {setWiFiPassword(e.target.value);}}
                />
            </Box>
                <Box width="40%"  justifyContent="space-between" padding={3}>
                    <MTextField
                        label="PPG frequency"
                        value={ppgFrequency}
                        disabled={!isEdit}
                        variant="standard"
                        onChange={(e) => {setPpgFrequency(e.target.value);}}
                    />
                    <br/><br/>

                {
                    ECGDataCollectionFrequency.map((schedule, key) => (
                        <Box mb={2} key={key}>
                            <MTimePicker
                                label={`ECG Reminder ${key + 1}`}
                                disabled={!isEdit}
                                variant="standard"
                                value={schedule.value}
                                onChange={(e) => {
                                    const newECGDataCollectionFrequency = [...ECGDataCollectionFrequency];
                                    if (e) {
                                        newECGDataCollectionFrequency[key].value = e;
                                    }
                                    setECGDataCollectionFrequency(newECGDataCollectionFrequency);
                                }}
                            />
                            {
                                isEdit && ECGDataCollectionFrequency.length - 1 === key && ECGDataCollectionFrequency.length !== MAX_ECG_REMINDERS && (
                                    <IconButton
                                        sx={{marginLeft: 1}}
                                        onClick={() => {
                                            const newECGDataCollectionFrequency = [...ECGDataCollectionFrequency, ecgReminder];

                                            setECGDataCollectionFrequency(newECGDataCollectionFrequency);
                                        }}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                )
                            }
                            {
                                isEdit  && (
                                    <IconButton
                                        sx={{marginLeft: 1}}
                                        onClick={() => {
                                            delete ECGDataCollectionFrequency[key];
                                            const newECGDataCollectionFrequency = [...ECGDataCollectionFrequency].filter(i => !!i);
                                            setECGDataCollectionFrequency(newECGDataCollectionFrequency);
                                        }}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                )
                            }

                        </Box>
                    ))
                }
                {
                    isEdit && (
                    <Box display="flex" alignItems="center">
                        <MButton color="error" onClick={() => {
                            setIsEdit(false);
                            setECGDataCollectionFrequency(ECGDefaultDataCollectionFrequency);
                        }}>Cancel</MButton>
                        <MButton sx={{ ml: 2 }} onClick={saveDeviceConnection}>Save</MButton>
                    </Box>)
                }
            </Box>
                <Box width="30%"  display="flex" padding={3} flexDirection="column" alignItems="left">
                    <MButton sx={{marginBottom:5, marginTop:3}} onClick={disableDeviceConnection}>Disconnect device</MButton>
                </Box>
            </Box>

        </Box>

    }

    return (
        <>
            <Snackbar open={isShowSnackbar}

                      autoHideDuration={1100}
                      anchorOrigin={{vertical:"top",horizontal:"center"}}
                      onClose={()=>{setIsShowSnackbar(false)}}
            >
                <Alert severity={typeSnackbar}
                       onClose={()=>{setIsShowSnackbar(false)}}
                       sx={{ width: '100%' }}>
                    {textSnackbar}
                </Alert>
            </Snackbar>
            <Box  justifyContent="space-between" padding={2} mt={2} sx={{ borderRadius: '5px', border: 0.5}}>
                <Box mb={1}>
                    <Typography variant="body2" fontStyle={{"color":"#4F4949"}}>Device : <b>{item.serial_number}</b></Typography>
                </Box>
                <Divider/>
                {
                    item.connection == null && (
                        <>
                            <br/>
                            <Typography variant="body2" fontStyle={{"color":"#4F4949"}}><i>Device is not connected to any study</i></Typography>
                        </>
                    )
                }
                {
                    item.connection != null && (
                        getConnection()
                    )
                }

            </Box>
        </>
    );
}
