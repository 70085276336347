import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import {datesDifference} from "../utils/utils";
import EditIcon from '@mui/icons-material/Edit';
import {Switch} from "@mui/material";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import MRadio from "./MRadio";
import {NewDeviceTypeOption} from "../api/device";
import {MButton} from "./MButton";
import MModal from './MModal';
import ClinicianForm from "../containers/ClinicianForm";
import {baseUrls} from "../routes";
import {deleteStaffProfile, updateStaffProfile} from "../api/clinic_staff";
//import MyDocument from "./СredentialsPDF";
// import ReactPDF from "@react-pdf/renderer";
//import PDFDownloadLink = ReactPDF.PDFDownloadLink;

export type FieldType = 'name' | 'status' | 'report';

let popupsData = Object({
    "activate" : {
        "header" : "Account activation",
        "body" : "Confirm account activation"
    },
    "de-activate" : {
        "header" : "Account de-activation",
        "body" : "Confirm account de-activation"
    },
    "delete" : {
        "header" : "Account deletion",
        "body" : "Confirm account deletion"
    }
})
export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface MClientAccountsTableProps {
    headings: TableHeading[];
    rows: any[];
    sx?: SxProps<Theme>;
    navigate:any;
    clientId?: string;
}

export const MClientAccountsTable = (props: MClientAccountsTableProps) => {
    const { headings, rows, sx,navigate,clientId } = props;

    const [dataRows, setDataRows] = useState(rows);
    const [popupType,setPopupType] = useState("delete");
    const [isPopUpOpen,setIsPopUpOpen] = useState(false);
    const [selectedRow,setSelectedRow] = useState(null);

    const prepareDataForActivationStatusChange = (row:any) => {
        return {
            "new_is_active" : row.is_active,
            "action" : "change_is_active"
        }
    }

    const handleCellAction = async() => {
        let data = null;
        let id = (selectedRow !== null) ? selectedRow['id'] : "";

        switch(popupType){
            case "delete":
                if(selectedRow !== undefined){
                    await deleteStaffProfile(id);

                }

                break
            case "activate":
                data = prepareDataForActivationStatusChange(selectedRow);
                await updateStaffProfile(id,data);

                break
            case "de-activate":
                data = prepareDataForActivationStatusChange(selectedRow);
                await updateStaffProfile(id,data);

                break
        }
        setIsPopUpOpen(false);
        //window.location.reload();
    }

    const renderCell = (heading: TableHeading, row: any,index: number) => {
        let value = row[heading.field]
        switch (heading.field) {
            case 'name':
                return value;
            case 'last_token_obtain':
                console.log(value)
                if(value === null || value === undefined){
                    return "Never"
                }
                return datesDifference(new Date(value*1000))
            case 'is_active':
                return <Switch
                    checked={row.is_active}
                    onChange={(e)=>{
                        const new_rows = [...dataRows];
                        new_rows[index].is_active = e.target.checked;
                        setSelectedRow(row);
                        setDataRows(new_rows);
                        setIsPopUpOpen(true);
                        setPopupType((e.target.checked)? "activate":"de-activate");
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            case 'actions':
                return <Box display="flex">
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {}}
                    >
                        <EditIcon color="primary" onClick={()=>{navigate(baseUrls.clinics + "/" + clientId + "/edit/"+row.id)}}/>
                    </Box>
                    <Box sx={{ cursor: 'pointer', "margin-left":"10pt"}} onClick={() => {}}>
                        <DeleteRoundedIcon
                            color="error"
                            onClick={()=>{
                                setSelectedRow(row);
                                setIsPopUpOpen(true);
                                setPopupType( "delete");
                            }}
                        />
                    </Box>
                </Box>
            default:
                return value;
        }
    };

    return (
        <>
            <MModal
                open={isPopUpOpen}
                handleClose={() => {
                    setIsPopUpOpen(false)
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 10}} paragraph={true}>
                        {popupsData[popupType]['header']}
                    </Typography>
                    <Typography variant="body1" sx={{mb: 15}}>
                        {popupsData[popupType]['body']}
                    </Typography>
                    <Box sx={{textAlign: "center"}}>
                        <MButton sx={{mr: 2}} color="error" onClick={() => {setIsPopUpOpen(false)}}
                        >Cancel</MButton>
                        <MButton onClick={() => {handleCellAction()}}>Confirm</MButton>
                    </Box>
                </Box>
            </MModal>

            <TableContainer component={Paper} sx={sx}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headings.map((heading: TableHeading, key: number) => (
                            <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataRows.map((row: any, key: number) => (
                        <TableRow key={key} >
                            {headings.map((heading: TableHeading, cellKey: number) => (
                                <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${cellKey}`}>
                                    {renderCell(heading, row, key) || '—'}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            </>
    );
}

export default MClientAccountsTable;
