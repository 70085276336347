import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import StudyForm from "../containers/StudyForm";
import { createStudy, createDraftStudy } from '../api/study';
import ClinicianForm from "../containers/ClinicianForm";
import {baseUrls} from "../routes";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getClinicDetail} from "../api/clinic";
import {useCallback, useEffect, useState} from "react";


interface ClientPageInterface {
    action : "create" | "edit" | "view"
}
const ClinicAddAccount = (props: ClientPageInterface) => {
    const navigate = useNavigate();
    const {clinicId,staffProfileId } = useParams();
    const {action} = props;
    const [name, setName] = useState("");

    const getClientDetails =  useCallback(async () => {
        if (clinicId != null) {
            const data = await getClinicDetail(clinicId);
            setName(data['name']);
        }
    }, [clinicId])

    useEffect(() => {
        getClientDetails()
    }, [getClientDetails]);

    const getTitle = () => {
        switch(action){
            case "create":
                return "New account";
            case "edit":
                return "Edit account";
        }
        return ""
    }

    return (
        <>
            <PageHeader
                title={getTitle()}
                actions={[{
                    onClick: () => navigate(-1),
                    name: "Close",
                    color:"error"
                }]}
            />
            <Paper sx={{ marginBottom: 8}}>
                <ClinicianForm action={action} staffProfileId={staffProfileId} name={name} clinicId={clinicId}/>
            </Paper>
        </>
    );
};

export default ClinicAddAccount;