import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MStatusPlates } from './MStatusPlates'
import * as React from "react";
import MModal from "./MModal";
import {DeviceInfo} from "../containers/DeviceInfo";
import {useState} from "react";
import {MeasurementInfo} from "../containers/MeasurementInfo";
import {Drawer, Typography} from "@mui/material";
import {baseUrls} from "../routes";
import MawiLogo from "../images/logo.svg";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {formatShortDescription} from "../utils/utils";
import MTextField from "./MTextField";
import {MButton} from "./MButton";
import {addFirmarePackage, deleteFirmwarePackage, updateFirmwarePackage} from "../api/device";

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string

}

interface MFirmwarePackagesTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

const defaultRow = {
    description: '',
    firmware_major: 1,
    firmware_minor: 0,
    firmware_build: 0,
    hardware_revision: "1.0"
}

export const MFirmwarePackagesTable = (props: MFirmwarePackagesTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow,setSelectedRow] = useState<any>(defaultRow);
    const [isEditModal,setIsEditModal] = useState<boolean>(false);
    const [selectedPackage,setSelectedPackage] = useState<any>(null);

    const sendDeleteFirmwarePackage = async() => {
        await deleteFirmwarePackage(selectedRow?.id);
        window.location.reload();
    }
    const formatMultipleValuesColumn = (i:number,value:string) => {
        return <>
            {i != 0 && <><br/><br/></>}
            {value}
        </>
    }
    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "link":
                return <a href={row.url}>Download</a>;
            case "description":
                return <div>
                    {/* Render each line with <br/> after it */}
                    {row.description.split('\n').map((line:string, index:number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </div>
            case "actions":
                return <Box display="flex">
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {}}
                    >
                        <EditIcon color="primary" onClick={()=>{
                            setSelectedRow(row);
                            setIsEditModal(true);
                            setIsEditModalOpen(true)
                        }}
                    />
                    </Box>
                    <Box sx={{ cursor: 'pointer', "margin-left":"10pt"}} onClick={() => {}}>
                        <DeleteRoundedIcon
                            color="error"
                            onClick={()=>{
                                setSelectedRow(row);
                                setIsDeleteModalOpen(true);

                            }}
                        />
                    </Box>
                </Box>
            default:
                return row[heading.field];
        }
    };

    const handleFirmwareChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const convertBase64 = (file:any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {

                let encoded = fileReader.result?.toString().replace(/^data:(.*,)?/, '');
                if(encoded){
                    if ((encoded?.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                }

            }
            fileReader.onerror = (error) => {
                console.log(error);
                reject(error);
            }
        })
    }

    const handleFileRead = async (event:any) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setSelectedPackage(base64);
    }

    const submitUpdatePackage = async() => {
        let data = {
            description: selectedRow.description,
            firmware_major: selectedRow.firmware_major,
            firmware_minor: selectedRow.firmware_minor,
            firmware_build: selectedRow.firmware_build,
            hardware_revision: selectedRow.hardware_revision,
            package_base64:selectedPackage
        }
        await updateFirmwarePackage(selectedRow.id,data);
        window.location.reload();
    }

    const submitPackage = async () => {
        let data = {
            description: selectedRow.description,
            firmware_major: selectedRow.firmware_major,
            firmware_minor: selectedRow.firmware_minor,
            firmware_build: selectedRow.firmware_build,
            hardware_revision: selectedRow.hardware_revision,
            package_base64:selectedPackage
        }
        await addFirmarePackage(data);
        window.location.reload();
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {setIsDeleteModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Firmware package deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{formatShortDescription(selectedRow?.firmware_major,
                        selectedRow?.firmware_minor,
                        selectedRow?.firmware_build,
                        selectedRow?.hardware_revision)}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {setIsDeleteModalOpen(false)}}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={() => {
                            sendDeleteFirmwarePackage();
                        }} >Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {setIsEditModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Firmware package
                    </Typography>

                    <Typography variant="h6">
                        Package short name
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2, mt:1}}>
                        {formatShortDescription(selectedRow?.firmware_major,
                            selectedRow?.firmware_minor,
                            selectedRow?.firmware_build,
                            selectedRow?.hardware_revision)}
                    </Typography>
                    <MTextField
                        type="number"
                        sx={{textAlign: "center", mb: 2}}
                        label="Major version"
                        value={selectedRow?.firmware_major}
                        onChange={(e) => {handleFirmwareChange("firmware_major",
                            parseInt(e.target.value, 10))}}
                    />
                    <MTextField
                        type="number"
                        sx={{textAlign: "center", mb: 2}}
                        label="Minor version"
                        value={selectedRow?.firmware_minor}
                        onChange={(e) => {handleFirmwareChange("firmware_minor",
                            parseInt(e.target.value, 10))}}
                    />
                    <MTextField
                        type="number"
                        sx={{textAlign: "center", mb: 2}}
                        label="Build version"
                        value={selectedRow?.firmware_build}
                        onChange={(e) => {handleFirmwareChange("firmware_build",
                            parseInt(e.target.value, 10))}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Hardware revision"
                        value={selectedRow?.hardware_revision}
                        onChange={(e) => {handleFirmwareChange("hardware_revision",e.target.value)}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Description(Put '\n' to add line break)"
                        multiline={true}
                        value={selectedRow?.description}
                        onChange={(e) => {handleFirmwareChange("description", e.target.value)}}
                    />
                    <MTextField
                        id="originalFileName"
                        type="file"
                        inputProps={{ accept:  '.bin' }}
                        required
                        label="Package file"
                        name="originalFileName"
                        size="medium"
                        variant="standard"
                        onChange={e => handleFileRead(e)}
                    />

                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {setIsEditModalOpen(false)}}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={async () => {
                            if(isEditModal){
                                await submitUpdatePackage();
                            } else {
                                await submitPackage();
                            }
                            }} >Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb:2}}
            >

                <Typography variant="h4">
                    Firmware packages
                </Typography>

                <MButton variant="contained" color="primary" onClick={() => {
                    setIsEditModal(false);
                    setIsEditModalOpen(true);
                    setSelectedRow(defaultRow)
                }}>
                    Add firmware package
                </MButton>
            </Box>
            <TableContainer component={Paper} sx={sx}>

                <Table>
                    <TableHead>
                        <TableRow>
                            {headings.map((heading: TableHeading, key: number) => (
                                <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, key: number) => (
                            <TableRow key={key}>
                                {headings.map((heading: TableHeading, key: number) => (
                                    <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                        {renderCell(heading, row) || '—'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default MFirmwarePackagesTable;
