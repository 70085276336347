import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MStatusPlates } from './MStatusPlates'

export type FieldType = 'name' | 'status' | 'report';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface MStudyTableProps {
  headings: TableHeading[];
  rows: any[];
  onRowClick?: (id: string) => void;
  sx?: SxProps<Theme>;
}

export const MStudyTable = (props: MStudyTableProps) => {
  const { headings, rows, sx, onRowClick } = props;

  const renderCell = (heading: TableHeading, row: any) => {
    switch (heading.type) {
        case 'name':
          return <b>{row[heading.field]}</b>;
        case 'status':
          return <MStatusPlates value={row[heading.field]} />;
        case 'report':
          return row.report ? (
          <Box 
            sx={{ 
              textDecoration: 'underline',
              fontWeight: '600'
            }} 
            onClick={(e) => {
              e.stopPropagation();
              window.open(row.report, "_blank");
            }}
          >
            View Final Report
          </Box>
          ) : '—';
        default:
          return row[heading.field];
    }
};

  return (
    <TableContainer component={Paper} sx={sx}>
      <Table>
        <TableHead>
          <TableRow>
            {headings.map((heading: TableHeading, key: number) => (
              <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, key: number) => (
            <TableRow key={key} sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }} onClick={() => onRowClick && onRowClick(row.id)}>
              {headings.map((heading: TableHeading, key: number) => (
                <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                  {renderCell(heading, row) || '—'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MStudyTable;
