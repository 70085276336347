import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {SxProps, Theme} from '@mui/system';
import {MStatusPlates} from './MStatusPlates'
import * as React from "react";
import MModal from "./MModal";
import {DeviceInfo} from "../containers/DeviceInfo";
import {useState} from "react";
import {MeasurementInfo} from "../containers/MeasurementInfo";
import {Drawer, Typography} from "@mui/material";
import {baseUrls} from "../routes";
import MawiLogo from "../images/logo.svg";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {formatShortDescription} from "../utils/utils";
import MTextField from "./MTextField";
import {MButton} from "./MButton";
import {addFirmarePackage, deleteFirmwarePackage, updateFirmwarePackage} from "../api/device";
import {addInfrastructure, deleteInfrastructure, updateInfrastructure} from "../api/infrastructures";

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string

}

interface MInfrastructuresTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}


export const MInfrastructuresTable = (props: MInfrastructuresTableProps) => {
    const {headings, rows, sx, onRowClick} = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isEditModal, setIsEditModal] = useState<boolean>(false);
    const [selectedInfrastructure, setSelectedInfrastructure] = useState<any>(null);

    const sendDeleteInfrastructure = async () => {
        await deleteInfrastructure(selectedRow?.id);
        window.location.reload();
    }
    const formatMultipleValuesColumn = (i: number, value: string) => {
        return <>
            {i != 0 && <><br/><br/></>}
            {value}
        </>
    }
    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "actions":
                return <Box display="flex">
                    <Box
                        sx={{cursor: 'pointer'}}
                        onClick={() => {
                        }}
                    >
                        <EditIcon color="primary" onClick={() => {
                            setSelectedRow(row);
                            setIsEditModal(true);
                            setIsEditModalOpen(true)
                        }}
                        />
                    </Box>
                    <Box sx={{cursor: 'pointer', "margin-left": "10pt"}} onClick={() => {
                    }}>
                        <DeleteRoundedIcon
                            color="error"
                            onClick={() => {
                                setSelectedRow(row);
                                setIsDeleteModalOpen(true);

                            }}
                        />
                    </Box>
                </Box>
            default:
                return row[heading.field];
        }
    };

    const handleInfrastructureChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };


    const submitUpdatePackage = async () => {
        let data = {
            data: selectedRow.data,
            dashboard: selectedRow.dashboard,
            device: selectedRow.device,
            users: selectedRow.users,
            name: selectedRow.name
        }
        await updateInfrastructure(selectedRow.id, data);
        window.location.reload();
    }

    const submitPackage = async () => {
        let data = {
            data: selectedRow.data,
            dashboard: selectedRow.dashboard,
            device: selectedRow.device,
            users: selectedRow.users,
            name: selectedRow.name
        }
        await addInfrastructure(data);
        window.location.reload();
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {
                    setIsDeleteModalOpen(false)
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Infrastructure deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{selectedRow?.name}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {
                            setIsDeleteModalOpen(false)
                        }}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={() => {
                            sendDeleteInfrastructure();
                        }}>Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {
                    setIsEditModalOpen(false)
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Infrastructure
                    </Typography>

                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Name"
                        value={selectedRow?.name}
                        onChange={(e) => {handleInfrastructureChange("name",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Data"
                        value={selectedRow?.data}
                        onChange={(e) => {handleInfrastructureChange("data",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Device"
                        value={selectedRow?.device}
                        onChange={(e) => {handleInfrastructureChange("device",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Users"
                        value={selectedRow?.users}
                        onChange={(e) => {handleInfrastructureChange("users",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Dashboard"
                        value={selectedRow?.dashboard}
                        onChange={(e) => {handleInfrastructureChange("dashboard",
                            e.target.value)}}
                    />


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {
                            setIsEditModalOpen(false)
                        }}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={async () => {
                            if (isEditModal) {
                                await submitUpdatePackage();
                            } else {
                                await submitPackage();
                            }
                        }}>Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb: 2}}
            >

                <Typography variant="h4">
                    Infrastructures
                </Typography>

                <MButton variant="contained" color="primary" onClick={() => {
                    setIsEditModal(false);
                    setIsEditModalOpen(true);
                    setSelectedRow(null)
                }}>
                    Add infrastructure
                </MButton>
            </Box>
            <TableContainer component={Paper} sx={sx}>

                <Table>
                    <TableHead>
                        <TableRow>
                            {headings.map((heading: TableHeading, key: number) => (
                                <TableCell sx={{fontSize: 16}} key={key}>{heading.fieldName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, key: number) => (
                            <TableRow key={key}>
                                {headings.map((heading: TableHeading, key: number) => (
                                    <TableCell sx={{fontSize: 16}} component="th" scope="row"
                                               key={`${heading.field}_${key}`}>
                                        {renderCell(heading, row) || '—'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default MInfrastructuresTable;
