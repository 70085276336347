import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import MTextField from './MTextField';

interface MTimePickerProps {
  label?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  variant? : "outlined" | "filled" |  "standard";
}

export const MTimePicker = (props: MTimePickerProps) => {
  const { label, value = null, onChange, disabled, error, helperText, variant="outlined" } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {label && <InputLabel>{label}</InputLabel>}
      <TimePicker
          value={value}
          onChange={onChange}
          renderInput={(params) => <MTextField {...params} label=""
                                               disabled={disabled}
                                               error={error}
                                               variant={variant}
                                               helperText={helperText} />}
          disabled={disabled}
        />
    </LocalizationProvider>
  );
}

export default MTimePicker;
