import {SxProps, Theme} from "@mui/system";
import {useState} from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {addFirmarePackage, deleteFirmwarePackage, updateFirmwarePackage} from "../api/device";
import MModal from "./MModal";
import {Typography} from "@mui/material";
import {formatShortDescription} from "../utils/utils";
import {MButton} from "./MButton";
import MTextField from "./MTextField";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
    addIdOrganizationExternal,
    deleteIdOrganizationExternal,
    updateIdOrganizationExternal
} from "../api/id_organization_external";

export interface TableHeading {
    fieldName: string,
    field: string

}

interface MIdOrganizationExternalsTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

const defaultRow = {
    description: '',
    id_organization_external : '3'
}

export const MIdOrganizationExternalsTable = (props: MIdOrganizationExternalsTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow,setSelectedRow] = useState<any>(defaultRow);
    const [isEditModal,setIsEditModal] = useState<boolean>(false);

    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "id_organization_external":
                return '"'+row.id_organization_external+'"';
            case "actions":
                return <Box display="flex">
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {}}
                    >
                        <EditIcon color="primary" onClick={()=>{
                            setSelectedRow(row);
                            setIsEditModal(true);
                            setIsEditModalOpen(true)
                        }}
                        />
                    </Box>
                    <Box sx={{ cursor: 'pointer', "margin-left":"10pt"}} onClick={() => {}}>
                        <DeleteRoundedIcon
                            color="error"
                            onClick={()=>{
                                setSelectedRow(row);
                                setIsDeleteModalOpen(true);

                            }}
                        />
                    </Box>
                </Box>
            default:
                return row[heading.field];
        }
    };

    const handleItemChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const submitUpdateItem = async() => {
        let data = {
            description: selectedRow.description,
            id_organization_external: selectedRow.id_organization_external
        }
        await updateIdOrganizationExternal(selectedRow.id,data);
        window.location.reload();
    }

    const submitItem = async () => {
        let data = {
            description: selectedRow.description,
            id_organization_external: selectedRow.id_organization_external
        }
        await addIdOrganizationExternal(data);
        window.location.reload();
    }

    const submitDeleteItem = async() => {
        await deleteIdOrganizationExternal(selectedRow?.id);
        window.location.reload();
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {setIsDeleteModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Id Organization External deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{selectedRow.id_organization_external}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {setIsDeleteModalOpen(false)}}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={() => {
                            submitDeleteItem();
                        }} >Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {setIsEditModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        ID organization external
                    </Typography>

                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Value"
                        value={selectedRow?.id_organization_external}
                        onChange={(e) => {handleItemChange("id_organization_external",e.target.value)}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Description(Put '\n' to add line break)"
                        multiline={true}
                        value={selectedRow?.description}
                        onChange={(e) => {handleItemChange("description", e.target.value)}}
                    />


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {setIsEditModalOpen(false)}}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={async () => {
                            if(isEditModal){
                                await submitUpdateItem();
                            } else {
                                await submitItem();
                            }
                        }} >Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb:2}}
            >

                <Typography variant="h4">
                    ID organization externals
                </Typography>

                <MButton variant="contained" color="primary" onClick={() => {
                    setIsEditModal(false);
                    setIsEditModalOpen(true);
                    setSelectedRow(defaultRow)
                }}>
                    Add ID
                </MButton>
            </Box>
            <TableContainer component={Paper} sx={sx}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headings.map((heading: TableHeading, key: number) => (
                                <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, key: number) => (
                            <TableRow key={key}>
                                {headings.map((heading: TableHeading, key: number) => (
                                    <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                        {renderCell(heading, row) || '—'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default MIdOrganizationExternalsTable;


