import {useState, useEffect, useCallback} from "react";
import Box from "@mui/material/Box";
import PageHeader from "../containers/PageHeader";
import Typography from "@mui/material/Typography";
import {addRegion, getRegionsList} from "../api/region";
import {MModal} from "../components/MModal";
import {MSelect} from "../components/MSelect";
import {MTextField} from "../components/MTextField";
import {MButton} from "../components/MButton";
import MList from "../components/MList";
import {useNavigate} from 'react-router-dom';

import {renderAccordionCompanies, renderAccordionPPG} from "../components/MAccordion";
import {addClinic, getClinicsList} from "../api/clinic";
import {renderAccordionHeaderCompaniesList, renderAccordionHeaderPPG} from "../components/MAccordionHeader";
import {renderAccordionDetailsCompaniesList, renderAccordionDetailsPPG} from "../components/MAccordionDetails";
import {baseUrls} from "../routes";
import MRadio from "../components/MRadio";
import {CircularProgress, Paper} from "@mui/material";
import * as React from "react";
import MCheckbox from "../components/MCheckbox";
import {getHeartRateList} from "../api/study";
import moment from "moment/moment";
import UnitsListGrid from "../containers/UnitsListGrid";




export const Regions = () => {


    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [regionsList, setRegionsList] = useState<any[]>([]);
    const [newRegionName, setNewRegionName] = useState("");
    const [isProcessing,setIsProcessing] = useState(false);


    const fetchRegionsList = useCallback(async () => {
        const regionsList = await getRegionsList();
        console.log(regionsList);
        let newRegionsList = regionsList;
        newRegionsList.sort((a:any, b:any) => b.departments_number - a.departments_number);
        console.log(newRegionsList);
        setRegionsList(newRegionsList);
    }, [])

    useEffect(() => {
        fetchRegionsList()
    }, [fetchRegionsList]);



    // const openDeviceManagement = () => {
    //     navigate(baseUrls.clientsTools)
    // }

    const onRegionCreation = async () => {
        setIsProcessing(true);
        let data = {
            "name" : newRegionName
        }
        await addRegion(data);
        window.location.reload();
        //navigate(-1);
    }



    const renderModalContent = () => (
        <Box p={3}>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant="h3">Add new Region</Typography>
            </Box>
            <Box>
                <MTextField
                    label="Region name"
                    onChange={(e) => setNewRegionName(e.target.value)}
                />
            </Box>
            <Box
                mt={8}
                sx={{
                    "& .MuiButton-root:not(:last-child)": {marginRight: 2},
                    textAlign: 'center'
                }}
            >
                <MButton onClick={handleClose} color={"inherit"}>Cancel</MButton>
                <MButton onClick={onRegionCreation} color={"primary"}>Confirm</MButton>
                {isProcessing && <CircularProgress hidden={!isProcessing} />}
            </Box>
        </Box>
    )

    return (
        <>
            <MModal open={open} handleClose={handleClose} width={480} height="auto">
                {renderModalContent()}
            </MModal>
            <PageHeader title="Regions"
                        subTitle="high-level entities"
                        actions={[
                {onClick: handleOpen, name: "Add Region"}]}/>
            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                <UnitsListGrid type="region" data={regionsList}/>
            </Paper>
        </>
    );
}
export default Regions;