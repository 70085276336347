export const studiesList = [
    {
        value: "holter",
        label: "Holter(Up to 7 days)"
    },
    {
        value: "two_week_holter",
        label: "2-week Holter(Up to 14 days)"
    },
    {
        value: "rpm",
        label: "Mawi Watch"
    }
]

export const studyStatuses = [
    {
        value: "inprogress",
        label: "In progress"
    },
    {
        value: "interpretating",
        label: "Interpretating"
    },
    {
        value: "completed",
        label: "Completed"
    }
]

export const languages = [
    {
        value: "EN",
        label: "English(EN)"
    },
    {
        value: "UK",
        label:"Ukrainian(UK)"
    }
]

export const analysisPlatforms = [
    {
        value: 'amps',
        label: 'AMPS',
    }
]

