import axios from "axios";
import {toast} from 'react-toastify';
import {getRandomValueFromLocalStorage, getValueFromLocalStorage, refreshToken, setValueToLocalStorage} from './auth';
import {config} from "../config"

export const GEO_SERVICE_URL = config.usersUrls
export const getBaseURL = async (email:string = "") => {
  let infrastructure = await getRandomValueFromLocalStorage('infrastructure');
  alert(!infrastructure);
  alert(infrastructure);
  if(!infrastructure){
    let response = await getInfrastructure(email);
    await setValueToLocalStorage('infrastructure',response.data);
    return response.data.dashboard;
  }
  return infrastructure['dashboard'];
}

export const getDataURL = () => {

}

export const getDeviceURL = () => {

}

export const getUsersUrl = () => {

}

export const getInfrastructure = async (email:string) => {
  return await networkLayer('get', `${GEO_SERVICE_URL}/external_api/geo/route_device/${email}/`);
}


export type GeneralApiProblem = { ok: false, status: ErrorResponseStatus, message?: string, isUnauthorized: boolean, data?: object}

export interface ApiErrorResponse {
  status: number,
  data: {
    message: string | undefined,
    Message: string | undefined
    error: string | undefined
  }
}

export enum ErrorResponseStatus {
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  badRequest = 400,
  serverError = 500,
  badGateway = 502,
  cannotConnect = 0,
  unknown = -1
}

type Method = 'get' | 'post' | 'put' | 'delete' | 'patch';

const generateErrorByCode = (code: string) => {
  switch(code){
    case "INVALID_DATA": return "Invalid data";
    case "MISSING_DATA": return "Missing fields";
    case "NOT_EXISTS": return "Doesn't exists";
    case "ALREADY_EXISTS": return "Already exists";
    case "PASSWORD_ERROR": return "Password error";
    case "MISSING_PARENT_OBJECT": return "Missing parent object";
    case "ACCESS_FORBIDDEN": return "Access denied";
    default: return "Something went wrong";
  }
}

export const getGeneralApiProblem = (response: ApiErrorResponse): GeneralApiProblem => {
  if (!response) {
    return { ok: false, status: ErrorResponseStatus.cannotConnect, isUnauthorized: false }
  }

  let status: ErrorResponseStatus = ErrorResponseStatus.unknown

  if (Object.values(ErrorResponseStatus).includes(response.status)) {
    status = response.status
  }

  let isUnauthorized = [ErrorResponseStatus.unauthorized, ErrorResponseStatus.forbidden].includes(response.status)

  return {
    ok: false,
    status,
    isUnauthorized,
    message: response.data && (response.data.message || response.data.Message || response.data.error),
    data: response.data
  }
}

const handleError = async (e: any) => {
  let errorResponse: any = getGeneralApiProblem((e as any).response);

  if(errorResponse.data){

    if(errorResponse.status === ErrorResponseStatus.unauthorized) {
      await refreshToken();
    } else {
      toast.error(errorResponse.data.code ? generateErrorByCode(errorResponse.data.code) : errorResponse.data.detail )
    }
  }

  return errorResponse
}

const executeRequest = async (method: Method = 'get', url: string, values?: any, params?: any, responseType?: any,accessToken?: string) => {
  return axios({
    method,
    url,
    data: values,
    headers: { Authorization: accessToken ? `JWT ${accessToken}` : '',
      Accept: 'application/json'
    },
    params,
    responseType
  })
}

export const networkLayer = async (method: Method = 'get',
                                   url: string,
                                   values?: any,
                                   params?: any,
                                   responseType?: any,logoutOnError: boolean = false) => {
  try {
    const accessToken = await getValueFromLocalStorage('access');
    const response = await executeRequest(method,url,values,params,responseType,accessToken);

    return response.data;
  } catch (e) {

    if(logoutOnError){
      localStorage.clear();
      window.location.href = '/login';
    }
    await handleError(e);
    const accessToken = await getValueFromLocalStorage('access');
    try {
      const response = await executeRequest(method, url, values, params, responseType, accessToken);
      return response.data;
    } catch (e){
      // localStorage.clear();
      // window.location.href = '/login';
    }
  }
}
