import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { MStatusPlates } from "../components/MStatusPlates";
import { MReportItem } from "../components/MReportItem";

import {
  formatDateToShow,
  formatDateTimeToShow,
  formatStudyType,
  formatEnrollment,
  isMCT,
  isTwoWeekHolter
} from '../utils/utils';
import { FileUploader } from "react-drag-drop-files";
import {MButton} from "../components/MButton";
import {useState} from "react";
import MTextField from "../components/MTextField";
import * as React from "react";
import {getUploadingCredentials, updateStudyMeasurement} from "../api/study";
import {Credentials, Endpoint, S3} from "aws-sdk";
import {Chip} from "@mui/material";
import axios from "axios";
import Divider from "@mui/material/Divider";

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
              props.value,
          )}%`}</Typography>
        </Box>
      </Box>
  );
}

interface StudySummaryProps {
  values?: any;
  devices?: any[];
  devicesMCT?: any[];
  reports?: any[];
}

export const StudySummary = ({ values, devices = [], devicesMCT = [], reports = [] }: StudySummaryProps) => {

  const [file,setFile] = useState<any>(null);
  const [fileSize, setFileSize] = useState(0);
  const [chunkSize, setChunkSize] = useState(6 );
  const [fileName, setFileName] = useState(null);

  const [uploadingCredentials, setUploadingCredentials] = useState(null);

  const [isFileChoosen, setIsFileChoosen] = useState(false);
  const [isUploadingStarted, setIsUploadingStarted] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);



  const handleFileRead = async (event:any) => {

    const newFile = event.target.files[0]

    setFile(newFile);
    setFileName(newFile.name);
    let newFileSize = (newFile.size / (1024 * 1024));//.toFixed(2);
    setFileSize(newFileSize);
    setIsFileChoosen(true);
  }

  const startFileUploading = async() => {
    setIsUploadingStarted(true);
    let credentials = await getUploadingCredentials();
    setUploadingCredentials(credentials.data);



    const s3Endpoint = new Endpoint("s3-eu-west-1.amazonaws.com");

    const s3Credentials = new Credentials({
      accessKeyId: credentials.data.access_key,
      secretAccessKey: credentials.data.secret_key,
      sessionToken:credentials.data.session_token
    })

    const s3 = new S3({
      endpoint: s3Endpoint,
      credentials: s3Credentials,
      apiVersion: '2006-03-01',
      signatureVersion: 'v4',
      region:"eu-west-1"
    })


    const params = {
      Bucket: credentials.data.bucket_name,
      Key: devices[0].id + ".edf",
    }


    const data = await s3.createMultipartUpload(params).promise();
    const uploadId = data.UploadId;

    let numberOfParts = Math.ceil(fileSize / chunkSize)


    let partsArray = [];
    for (let partNumber = 1; partNumber <= numberOfParts; partNumber++) {
      const partParams = {
        Bucket: credentials.data.bucket_name,
        Key: devices[0].id + ".edf",
        PartNumber: partNumber,
        UploadId: uploadId ? uploadId : ''
      };

      // Generate a pre-signed URL for each part
      const url = await s3.getSignedUrlPromise('uploadPart', partParams);
      //presignedUrls.push(url);

      const sentSize = (partNumber-1) * chunkSize * 1024 * 1024

      if(file){
        let chunk = file.slice(sentSize, sentSize + chunkSize * 1024 * 1024)

        let data = await axios.put(url, chunk, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        });


        let uploadedPart = {
          PartNumber: partNumber,
          // removing the " enclosing carachters from
          // the raw ETag
          ETag: data.headers.etag.replaceAll('"', ""),
        }

        partsArray.push(uploadedPart)


        let percent = (100 * partNumber / numberOfParts);
        setUploadingProgress(percent);

      }
    }

    const completeParams = {
      Bucket: credentials.data.bucket_name,
      Key: devices[0].id + ".edf",
      MultipartUpload: {
        Parts: partsArray,
      },
      UploadId: uploadId? uploadId : "",
    };

    s3.completeMultipartUpload(completeParams, async (err, data) => {
      if (err) {
        console.error("Error completing multipart upload:", err);
      } else {

        console.log(values)
        await updateStudyMeasurement({
          id: devices[0].id,
          date_upload: new Date(),
          id_unique:values.patient.id_unique
        })
        window.location.reload();
      }
    });


  }




  // const handleNext = async() => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   if (activeStep + 1  == 1){
  //
  //      await startFileUploading()
  //   }
  // };

  const renderDeviceAndDate = () => {
    const currentStudyType = (isMCT(values.study_type) || isTwoWeekHolter(values.study_type))

    if (currentStudyType) {
      return {
        devices: devicesMCT.length ? devicesMCT.map((d: any, key: number) => (
          <span key={key} style={{ display: 'block' }}>
            {d.device_serial_number}
          </span>
        )) : '—',
        startDate: values.date_start ? formatDateTimeToShow(values.date_start) : '—'
      }
    } else if (!currentStudyType) {
      return {
        devices: devices.length ? devices.map((d: any, key: number) => (
          <span key={key} style={{ display: 'block' }}>
            {d.device_serial_number}
          </span>
        )) : '—',
        startDate: values.date_start ? formatDateTimeToShow(values.date_start) : '—'
      }
    }

    return {
      devices: '—',
      startDate: '—'
    }
  }

  const patientInfo = [
    {
      label: 'Status',
      value: <MStatusPlates disableStyling value={values.status} />
    },
    {
      label: 'Date Created',
      value: formatDateToShow(values.date_start)
    },
    {
      label: 'Recording Type',
      value: formatStudyType(values.study_type)
    },
    {
      label: 'Enrollment',
      value: formatEnrollment(values.enrollment)
    },
    {
      label: 'Device ID',
      value: renderDeviceAndDate().devices
    },
    {
      label: 'Test start time',
      value: renderDeviceAndDate().startDate
    }
  ]




  const renderDeviceUploading = (device:any,index:number) => {
    return <>
      <Divider />
      <Box display="flex" flexDirection="row" sx={{width:'100%',mt:"1%",mb:"1%"}} alignItems="center">
        <Box display="flex" flexDirection="row" sx={{width:'20%'}}>
          <Typography sx={{ width: 160, fontSize: 18, fontWeight: 600, pl: 2 }}>{device.device_serial_number}</Typography>
        </Box>
        <Box sx={{width:'15%'}}>
          {(device.date_upload !== "None")?
              <Chip label="Uploaded" color="success" variant="outlined" /> :
              <Chip label="Pending upload" color="primary" variant="outlined" />
          }
        </Box>
        <Box sx={{width:'30%'}} justifyContent="center" display="flex" flexDirection="column">
          {device.date_upload === "None" ?
              (!isUploadingStarted) ?
          <MTextField
                  id="originalFileName"
                  type="file"
                  inputProps={{ accept:  '.edf' }}
                  required
                  label=""
                  name="originalFileName"
                  size="medium"
                  variant="standard"
                  onChange={e => handleFileRead(e)}
                  sx={{width:"100%"}}
              /> :
              <>
                <div>Selected file: {fileName}</div>
                <div>File size: {fileSize.toFixed(2)} mb</div>
              </>
              :
              null
          }
        </Box>
        <Box sx={{width:'30%', ml:'1%'}} >
          {device.date_upload === "None" && isFileChoosen ?
          ( !isUploadingStarted ) ?
              <MButton onClick={startFileUploading}>Start uploading</MButton>
              :
              <LinearProgressWithLabel value={uploadingProgress} />
          :
          null
          }
        </Box>

      </Box>
      <Divider />
    </>
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={7.5}>
        {
          patientInfo.map((pi, key) =>(
            <Box key={key}>
              <Typography sx={{ color: 'rgba(79, 73, 73, 0.5)', fontSize: 14, pb: 0.5 }}>{pi.label}</Typography>
              <Typography>{pi.value}</Typography>
            </Box>
          ))
        }
      </Box>
      <Box sx={{width:"100%"}}>
        {devices.length > 0 && devices[0].device_serial_number.length > 5 && <>
          <Typography variant="h5">Pending EDF uploads</Typography>
          <br/>
          {devices && devices?.map((device, index) => (
                  renderDeviceUploading(device,index)
          ))}
        </>
        }

        <br/>

      </Box>

      {
        (!!reports.length || values.report) && (
          <>
            <Typography variant="h5">Reports</Typography>
            <Box pt={3}>
              <>
                {reports && reports.map((report, key) => (
                  <MReportItem 
                    key={key}
                    title="Report"
                    reportDate={report.date_start}
                    url={report.url}
                    isLastChild={reports.length - 1 === key}
                  />
                ))}
                {values.report && (
                  <MReportItem 
                    reportDate={values.date_start}
                    url={values.report}
                    isLastChild
                  />
                )}
              </>
            </Box>
          </>
        )
      }
    </> 
  );
};

export default StudySummary;