import { createContext, useContext, useMemo, ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrls } from "../routes";
import { useLocalStorage } from "./useLocalStorage";
import { refreshToken } from "../api/auth";

export interface User {
  access: string;
  refresh: string;
  staff_profile: any;
}

interface AuthContextInterface {
  user: User | null,
  login: (data: User) => void,
  logout: () => void
}

const defaultAuthContext = {
  user: null,
  login: (data: User) => {},
  logout: () => {}
};

const AuthContext = createContext<AuthContextInterface>(defaultAuthContext);

interface AuthProviderInterface {
  children: ReactElement;
}


export const isClinician = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === 'clinician';
}

export const isTechnician = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === 'technician';
}

export const isAdmin = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === 'it-admin' || user?.staff_profile?.role === 'super-admin';
}

export const isSuperAdmin = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === 'it-admin' || user?.staff_profile?.role === 'super-admin';
}

export const AuthProvider = ({ children }: AuthProviderInterface) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const intervalId = setInterval(() => { refreshToken() }, 2999);
  //
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  // call this function when you want to authenticate the user
  const login = async (data: User) => {
    setUser(data);

    if (isClinician(data)) {
      navigate(baseUrls.studies);
    }

    if (isTechnician(data)) {
      navigate(baseUrls.reports);
    }

    if (isAdmin(data) || isSuperAdmin(data)) {
      navigate(baseUrls.regions);
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate(baseUrls.login, { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};