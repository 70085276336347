import { useNavigate } from "react-router-dom";
import { MStudyTable, FieldType } from '../components/MStudyTable';
import { formatName, formatEnrollment, formatDateToShow, isDraft, formatStudyType } from '../utils/utils';
import { baseUrls } from "../routes";
import MClientAccountsTable from "../components/MClientAccountsTable";

const tableHeadings = [
    {
        fieldName: 'Name',
        field: 'name'
    },
    {
        fieldName: 'Email',
        field: 'email'
    },
    {
        fieldName: 'Position',
        field: 'role'
    },
    {
        fieldName: 'Last online',
        field: 'last_token_obtain'
    },
    {
        fieldName: 'Is active',
        field: 'is_active'
    },

    {
        fieldName: 'Actions',
        field: 'actions',
        type: 'actions' as FieldType
    }
];

interface ClientAccountsGridProps {
    data: any[];
    clinicId?: string;
}

export const ClientAccountsGrid = (props: ClientAccountsGridProps) => {
    const navigate = useNavigate();

    const { data, clinicId } = props;

    const rows = data.map((row) => ({
        ...row,
        name: row.name,
        email : row.email,
        last_token_obtain : row.last_token_obtain,
        is_active : row.is_active
    }));

    return (
        <MClientAccountsTable
            headings={tableHeadings}
            rows={rows}
            clientId={clinicId}
            navigate={navigate}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
        />
    );
};

export default ClientAccountsGrid;