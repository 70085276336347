import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { StudyStatuses } from '../api/study';

interface MStatusPlatesProps {
  value: StudyStatuses;
  disableStyling?: boolean
}

export const MStatusPlates = (props: MStatusPlatesProps) => {
  const { value, disableStyling } = props;

  const defaultStyles = {
    paddingX: 1.5,
    paddingY: 1,
    borderRadius: 1.5,
    display: 'inline-flex',
    '& > p': {
      fontWeight: '700'
    }
  }

  const renderValue = () => {
    switch (value) {
      case 'draft':
        return {
          sx: {
            ...defaultStyles,
            border: '1px solid #808080',
            color: '#808080'
          },
          label: 'Draft'
        };
      case 'inprogress':
        return {
          label: 'In progress',
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
      case 'interpretating':
        return {
          label: 'Interpretating...',
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
      case 'completed':
        return {
          label: 'Completed',
          sx: {
            ...defaultStyles,
            background: '#CEE0C7'
          }
        };  
      default:
        return {
          label: '-',
          sx: {
            ...defaultStyles,
            background: 'grey'
          }
        };
    }
  }

  if (!renderValue()) return <></>;
  if (disableStyling) return <>{renderValue()?.label}</>;

  return (
    <Box sx={renderValue()?.sx}>
      <Typography>{renderValue()?.label}</Typography>
    </Box>
  );
}

export default MStatusPlates;
