import PageHeader from "../containers/PageHeader";
import Paper from "@mui/material/Paper";
import {MTextField} from "../components/MTextField";
import {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MList from "../components/MList";
import {DeviceInfo} from "../containers/DeviceInfo";
import {adminAddDevice, adminListDevices, NewDeviceTypeOption} from "../api/device";
import {MModal} from "../components/MModal";
import {MButton} from "../components/MButton";
import MRadio from "../components/MRadio";
import {MeasurementInfo} from "../containers/MeasurementInfo";
import {listStudiesAdmin} from "../api/study";
import AdminMeasurementsGrid from "../containers/AdminMeasurementsGrid";
import AdminDevicesGrid from "../containers/AdminDevicesGrid";
import {languages, studiesList, studyStatuses} from "../utils/constants";
import MSelect from "../components/MSelect";
import * as React from "react";
import {getClustersList} from "../api/cluster";
import {getClinicsList} from "../api/clinic";
import MDatePicker from "../components/MDatePicker";
import {formatDateAPI, formatDateToShow} from "../utils/utils";
import {getRegionsList} from "../api/region";

const newDeviceTypeOptions = [
    {
        value: "mawi_watch",
        label: "Mawi Watch(RPM)"
    },
    {
        value: "mawi_patch",
        label: "Mawi Patch(Holter)"
    },
    {
        value: "mawi_vital",
        label: "Mawi Vital(RPM)"
    }

];


export const AdminSearch = () => {
    // Dropdown lists
    const [regions,setRegions] = useState([]);

    const [clusters,setClusters] = useState([]);
    const [clinics,setClinics] = useState([]);


    // Filter options
    const [region, setRegion] = useState<string | undefined>(undefined);
    const [cluster, setCluster] = useState<string | undefined>(undefined);
    const [clinic, setClinic] = useState<string | undefined>(undefined);

    const [deviceSN, setDeviceSN] = useState<string | undefined>(undefined);
    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);
    const [studyStatus,setStudyStatus] = useState<string | undefined>(undefined);
    const [studyType,setStudyType] = useState<string | undefined>(undefined);
    const [studyLanguage,setStudyLanguage] = useState<string | undefined>(undefined);



    const [measurements, setMeasurements] = useState<any[]>([]);
    const [isMeasurementAvailableMore, setIsMeasurementAvailableMore] = useState<boolean>(true);

    const [devices, setDevices] = useState<any[]>([]);
    const [isDevicesAvailableMore, setIsDevicesMore] = useState<boolean>(true);

    const [isDeviceAddOpen, setIsDeviceAddOpen] = useState<boolean>(false);

    const [newDeviceSN, setNewDeviceSN] = useState<string>("");
    const [newDeviceType, setNewDeviceType] = useState<NewDeviceTypeOption>("mawi_patch");


    useEffect(() => {
        setDevices([])
        setMeasurements([])
        if (deviceSN !== undefined && deviceSN.length > 2) {
            fetchDevices();
        }
        fetchMeasurements();
    }, [deviceSN,dateFrom,dateTo,cluster,clinic,studyStatus,studyType,studyLanguage]);

    const fetchRegionsList = useCallback(async () => {
        const regionsList = await getRegionsList();
        regionsList.sort((a:any, b:any) => b.departments_number - a.departments_number);

        let newList = regionsList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setRegions(newList);
    }, [])

    const fetchCompanyList = async (regionId:string) => {
        const companyList = await getClustersList(regionId);
        companyList.sort((a:any, b:any) => b.departments_number - a.departments_number);

        let newList = companyList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClusters(newList);
    }

    let fetchDepartmentsList = async (id: string) => {
        let data = await getClinicsList(id);
        let newDepartmentsList = data;
        console.log(newDepartmentsList);
        let newList = newDepartmentsList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClinics(newList);
        // @ts-ignore
        setClinic(undefined);

    }

    useEffect(() => {
        fetchRegionsList()
    }, [fetchRegionsList]);


    const fetchDevices = async () => {
        setIsDevicesMore(false);
        const devices = await adminListDevices(deviceSN)
        setDevices(devices.data.devices)
    }

    const fetchMeasurements = async () => {

        let filter_values = {
            'device_serial_number':deviceSN,
            'id_company':cluster,
            'id_department':clinic,
            'date_start__from':formatDateAPI(dateFrom),
            'date_start__to':formatDateAPI(dateTo),
            'study_language':studyLanguage,
            'study_type':studyType,
            'status':studyStatus
        }
        setIsMeasurementAvailableMore(false);
        const measurements = await listStudiesAdmin(filter_values)
        setMeasurements(measurements.data)
    }

    const addNewDevice = async () => {
        let data = {
            "serial_number": newDeviceSN,
            "device_type": newDeviceType,
        }
        await adminAddDevice(data)
        setIsDeviceAddOpen(false)
    }

    // const  renderBaseInfo = () => {
    //     return <Box>
    //            dsads
    //         </Box>
    //
    // }

    return (
        <>
            <MModal
                open={isDeviceAddOpen}
                handleClose={() => {
                    setIsDeviceAddOpen(false)
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Add new Device
                    </Typography>
                    <MTextField
                        sx={{textAlign: "center", mb: 4}}
                        label="Device Serial Number"
                        value={newDeviceSN}
                        onChange={(e) => {
                            setNewDeviceSN(e.target.value);
                        }}
                    />
                    <MRadio
                        label="Device type"
                        value={newDeviceType}
                        options={newDeviceTypeOptions}
                        onChange={(e) => setNewDeviceType(e.target.value as NewDeviceTypeOption)}
                    />
                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton color="error" onClick={() => {
                            setIsDeviceAddOpen(false);
                            setNewDeviceSN("")
                        }}>Cancel</MButton>
                        <MButton sx={{ml: 2}} onClick={addNewDevice}>Confirm it’s done</MButton>
                    </Box>
                </Box>
            </MModal>
            <PageHeader title="Device/Study Management" actions={
                [{
                    onClick: () => {
                        setIsDeviceAddOpen(true)
                    }, name: "Add device"
                }]
            }/>
            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                <Box>
                    <Box display="flex" flexDirection="row">
                        <Box width="40%" justifyContent="space-between">
                            <MSelect
                                label="Region"
                                value={region}
                                options={regions}
                                onChange={(e) => {
                                    setRegion(e.target.value);
                                    fetchCompanyList(e.target.value);
                                }}
                            />
                            <br/>
                            <br/>
                            <MSelect
                                label="Cluster"
                                value={cluster}
                                options={clusters}
                                onChange={(e) => {
                                    setCluster(e.target.value);
                                    fetchDepartmentsList(e.target.value);
                                }}
                            />
                            <br/>
                            <br/>
                            <MSelect
                                label="Clinic"
                                value={clinic}
                                options={clinics}
                                onChange={(e) => {
                                    setClinic(e.target.value);
                                }}
                            />
                        </Box>
                        <Box width="25%" sx={{ml:"5%" }} justifyContent="space-between" >
                            <MTextField
                                label="Device S/N"
                                value={deviceSN}
                                onChange={(e) => {
                                    setDeviceSN(e.target.value);
                                }}
                            />
                            <br/>
                            <br/>

                            <MDatePicker
                                label="Date start(from)"
                                value={dateFrom}
                                onChange={(value) => {
                                    setDateFrom(value);
                                }}
                            />
                            <br/>
                            <br/>
                            <MDatePicker
                                label="Date start(to)"
                                value={dateTo}
                                onChange={(value) => {
                                    setDateTo(value);
                                }}
                            />
                        </Box>
                        <Box sx={{ml:"5%"}} width="25%" justifyContent="space-between">

                            <MSelect
                                label="Study status"
                                value={studyStatus}
                                options={studyStatuses}
                                onChange={(e)=>setStudyStatus(e.target.value)}
                            />
                            <br/>
                            <br/>
                            <MSelect
                                label="Study type"
                                value={studyType}
                                options={studiesList}
                                onChange={(e)=>setStudyType(e.target.value)}
                            />
                            <br/>
                            <br/>
                            <MSelect
                                label="Study language"
                                value={studyLanguage}
                                options={languages}
                                onChange={(e)=>setStudyLanguage(e.target.value)}
                            />
                        </Box>
                    </Box>

                </Box>

                <Box my={2}>
                    <Box mb={2}>
                        <Typography variant="h6">Studies matching search criteria"</Typography>
                    </Box>
                    <AdminMeasurementsGrid
                        data={measurements}
                    />
                </Box>
                <Box my={2}>

                    <Box mb={4} mt={4}>
                        <Typography variant="h6">Devices matching "{deviceSN}" </Typography>
                    </Box>
                    <AdminDevicesGrid
                        data={devices}
                    />
                </Box>

            </Paper>
        </>
    );
};

export default AdminSearch;