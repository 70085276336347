import Button, {ButtonProps} from '@mui/material/Button';
import React, {useState} from "react";
import {CircularProgress} from "@mui/material";

export interface MButtonProps extends ButtonProps {
    id?: string;
}

export const MButton = (props: MButtonProps) => {
    const {variant,onClick,children} = props;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isAnimated, setIsAnimated] = useState(false);

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        // Disable the button and start the animation
        await setIsDisabled(true);
        await setIsAnimated(true);

        try {
            // Execute the function passed as a parameter
            if (onClick) {
                await onClick(event);
            }
        } finally {
            // Enable the button and finish the animation
            setIsDisabled(false);
            setIsAnimated(false);
        }
    };


    return (
        <>
            <Button
                {...props}
                variant={variant || "contained"}
                disabled={isDisabled}
                onClick={handleClick}
            >
                {children}
                {isAnimated && <CircularProgress />}
            </Button>
        </>
    );
}