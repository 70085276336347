import {useCallback, useEffect, useState} from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {MButton} from "../components/MButton";
import {MTextField} from "../components/MTextField";
import MDatePicker from "../components/MDatePicker";
import MDateTimePicker from "../components/MDateTimePicker";
import ReactJson from "react-json-view";
import InputLabel from "@mui/material/InputLabel";
import {getPatient, updatePatient} from "../api/patient";
import MSelect from "../components/MSelect";
import {Link, useNavigate} from "react-router-dom";
import {baseUrls} from "../routes";
import {getClinicDetail} from "../api/clinic";

const genderOptions = [
    {
        value: "male",
        label: "Male"
    },
    {
        value: "female",
        label: "Female"
    }
];

interface PatientInfoProps {
    newIdPatient: string;
}

export const PatientInfo = (newIdPatient: PatientInfoProps) => {
    //alert(newIdPatient);
    console.log(newIdPatient);
    const [idPatient, setIdPatient] = useState("");
    const [isEditProfile, setIsEditProfile] = useState<boolean>(false);
    const [patient, setPatient] = useState<any>(null);
    const [clientName, setClientName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setIdPatient(newIdPatient.newIdPatient);
    }, [newIdPatient]);

    const getClientDetails =  useCallback(async (id_department:string) => {

        const data = await getClinicDetail(id_department);
        setClientName(data['name']);

    }, [])

    const getPatientData = useCallback(async () => {
        console.log(idPatient,"before api");

        let profile = await getPatient(idPatient);
        setPatient(profile.data);

        getClientDetails(profile.data.id_department);

    },[idPatient])



    useEffect(() => {
        getPatientData();

    }, [getPatientData])

    const postUpdateUserProfile = async () => {
        let data = await updatePatient(patient);
        setIsEditProfile(false);
        console.log(data);
    }

    const handlePatientChange = (keyToUpdate: string, newValue: string) => {
        let newDict = {...patient};
        newDict[keyToUpdate] = newValue;
        setPatient(newDict);
    };

    return (
        <>
            <Box justifyContent="space-between" width="100%" mt={4}>
                <Box>
                    <Box display="flex" alignItems="center" mb={4}>

                        <Typography variant="h4"
                                    fontStyle={{"color": "#4F4949"}}>
                            {"Patient details(ID: "+patient?.unique_id+")"}
                        </Typography>
                        {!isEditProfile &&
                            <MButton variant="text"
                                     onClick={() => {
                                         setIsEditProfile(true);
                                     }}>
                                Edit
                            </MButton>
                        }
                        {isEditProfile &&
                            <>
                                <MButton variant="text"
                                         hidden={!isEditProfile}
                                         color="error"
                                         onClick={() => {
                                             setIsEditProfile(false);
                                             //getPatientData();

                                         }}>
                                    Cancel
                                </MButton>
                                <MButton variant="text"
                                         hidden={!isEditProfile}
                                         onClick={() => {
                                             setIsEditProfile(false);
                                             postUpdateUserProfile();
                                         }}>
                                    Save
                                </MButton>
                            </>
                        }

                    </Box>
                    <Box m="dense" display="flex">
                        <Box width="45%">
                            <Typography variant="h5">Clinic</Typography>
                            <br/>
                            <Link to={baseUrls.clinics+"/"+patient?.id_department}>
                                {clientName}
                                {/*{patient?.id_department}*/}
                            </Link>
                            <br/>
                            <br/>
                            <MTextField label="First name"
                                        value={patient?.first_name}
                                        variant="standard"
                                        disabled={!isEditProfile}
                                        onChange={(e) => {
                                            handlePatientChange("first_name", e.target.value);
                                        }}
                            />
                            <br/>
                            <br/>
                            <MSelect
                                label="Gender"
                                value={patient?.gender || ''}
                                defaultValue={genderOptions[0].value}
                                disabled={!isEditProfile}
                                options={genderOptions}
                                onChange={(e) => {
                                    handlePatientChange("gender", e.target.value);
                                }}
                            />

                            <br/>


                        </Box>
                        <Box width="45%" ml="10%">
                            <br/>
                            <br/>
                            <br/>
                            <MTextField label="Last name"
                                        value={patient?.last_name}
                                        variant="standard"
                                        disabled={!isEditProfile}
                                        onChange={(event) => {
                                            handlePatientChange("last_name", event.target.value);
                                        }}
                            />
                            <br/>
                            <br/>
                            <MDatePicker
                                label="Date of Birth"
                                disabled={!isEditProfile}
                                value={patient?.birthday}
                                onChange={(e) => {
                                    if(e !== null){
                                        handlePatientChange("birthday", e.toString());
                                    }
                                }}
                            />
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )
}
