import {Paper} from "@mui/material";
import PageHeader from "../containers/PageHeader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MRadio from "../components/MRadio";
import {addFirmarePackage, listFirmwarePackages, NewDeviceTypeOption} from "../api/device";
import {MButton} from "../components/MButton";
import MTextField from "../components/MTextField";
import MModal from "../components/MModal";
import {useCallback, useEffect, useState} from "react";
import * as React from "react";
// import {getClinicStaffList} from "../api/clinic";
import AdminMeasurementsGrid from "../containers/AdminMeasurementsGrid";
import FirmwarePackagesGrid from "../containers/FirmwarePackagesGrid";
import {formatShortDescription} from "../utils/utils";
import IDOrganizationExternalsGrid from "../containers/IDOrganizationExternalsGrid";
import {listIdOrganizationExternals} from "../api/id_organization_external";
import InfrastructuresGrid from "../containers/InfrastructureGrid";
import {listInfrastructures} from "../api/infrastructures";

export const PortalSettings = () => {
    const [fwPackages,setFwPackages] = useState([]);
    const [idOrganizationExternals,setIdOrganizationExternals] = useState([]);
    const [infrastructures,setInfrastructures] = useState([]);



    const fetchFwPackages = useCallback(async () => {
        let data = await listFirmwarePackages();

        setFwPackages(data);
    }, [])

    const fetchIds = useCallback(async () => {
        let data = await listIdOrganizationExternals();
        setIdOrganizationExternals(data);
    }, [])

    const fetchInfrastructures = useCallback(async () => {
        let data = await listInfrastructures();
        setInfrastructures(data);
        // let data = await listIdOrganizationExternals();
        // setIdOrganizationExternals(data);
    }, [])

    useEffect(() => {
        fetchFwPackages()
    }, [fetchFwPackages]);

    useEffect(() => {
        fetchIds()
    }, [fetchIds]);

    useEffect(() => {
        fetchInfrastructures()
    }, [fetchInfrastructures]);


    return (
        <>
            <PageHeader title="Portal Settings" actions={
                []
            }/>
            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                <Box sx={{mb:4}}>
                    <IDOrganizationExternalsGrid
                        data={idOrganizationExternals}
                    />
                </Box>
                <Box sx={{mb:4}} >
                    <InfrastructuresGrid
                        data={infrastructures}
                    />
                </Box>

                <FirmwarePackagesGrid
                    data={fwPackages}
                />
            </Paper>
        </>

    )

}