import { useNavigate } from "react-router-dom";
import { MStudyTable, FieldType } from '../components/MStudyTable';
import {
    formatName,
    formatEnrollment,
    formatDateToShow,
    isDraft,
    formatStudyType,
    formatShortDescription
} from '../utils/utils';
import { baseUrls } from "../routes";
import MClientAccountsTable from "../components/MClientAccountsTable";
import MFirmwarePackagesTable from "../components/MFirmwarePackagesTable";

const tableHeadings = [
    {
        fieldName: 'Short name',
        field: 'short_name'
    },
    {
        fieldName: 'Description',
        field: 'description'
    },
    {
        fieldName: 'Link',
        field: 'link'
    },
    {
        fieldName: 'Md5 Hash',
        field: 'md5'
    },
    {
        fieldName: 'Actions',
        field: 'actions'
    }

];

interface FirmwarePackagesGridProps {
    data: any[];
    // clientId?: string;
}

export const FirmwarePackagesGrid = (props: FirmwarePackagesGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row,
        short_name: formatShortDescription(row.firmware_major,
            row.firmware_minor,
            row.firmware_build,
            row.hardware_revision),
        description:row.description,
        md5:row.md5
    }));

    return (
        <MFirmwarePackagesTable
            headings={tableHeadings}
            rows={rows}

            // navigate={navigate}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
        />
    );
};

export default FirmwarePackagesGrid;