import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import StudyForm from "../containers/StudyForm";
import StudyFormEditDraft from "../containers/StudyFormEditDraft";
import StudySummary from "../containers/StudySummary";
import StudySummaryRPM from "../containers/StudySummaryRPM";
import { MTabs } from "../components/MTabs";
import {getStudy, getStudiesListDevices, getStudiesMCTListDevices, finishStudy} from '../api/study';
import { getClinicalReasons } from '../api/clinicalReasons';
import { getInsuranceInformation } from '../api/insuranceInforamation';
import {formatName, capitalizeFirstLetter, isDraft, isMCT, isRPM, isHolter, isTwoWeekHolter} from "../utils/utils";
import moment from "moment";
import {MModal} from "../components/MModal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {MButton} from "../components/MButton";

export const Study = () => {
  const { studyId } = useParams();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false); return false;};
  const [activeTab, setActiveTab] = useState<number>(0);
  const [study, setStudy] = useState<any>();
  const [patient, setPatient] = useState<any>();
  const [clinicalReasons, setClinicalReasons] = useState<any>();
  const [insuranceInformation, setInsuranceInformation] = useState<any>();
  const [listDevices, setListDevices] = useState<any>();
  const [listDevicesMCT, setListDevicesMCT] = useState<any>();

  const getData = useCallback(async (studyId) => {
    const study = await getStudy(studyId);

    if (isDraft(study.data)) {
      setPatient(study.data.draft_data.patient);
      setClinicalReasons(study.data.draft_data.clinicalReasons);
      setInsuranceInformation(study.data.draft_data.insuranceInformation);
    } else {
      const clinicalReasons = await getClinicalReasons(study.data.patient.id_unique);
      const insuranceInformation = await getInsuranceInformation(study.data.patient.id_unique);

      if (isMCT(study.data.study_type) || isTwoWeekHolter(study.data.study_type)) {
        const listDevicesMCT = await getStudiesMCTListDevices(study.data.id);
        setListDevicesMCT(listDevicesMCT.data.devices)
      }

      if (isHolter(study.data.study_type)){
        const listDevices = await getStudiesListDevices(study.data.id);
        setListDevices(listDevices.data.devices);
      }

      setPatient(study.data.patient);
      setClinicalReasons(clinicalReasons.data);
      setInsuranceInformation(insuranceInformation.data);
    }

    setStudy(study.data)
  }, [])

  useEffect(() => {
    getData(studyId)
  }, [getData, studyId]);

  if (!study) return <></>;

  const values = isDraft(study) ? {
    ...clinicalReasons,
    ...insuranceInformation,
    patient,
    device: study.draft_data.serialNumber,
    enrollment: study.draft_data.enrollment,
    study_type: study.draft_data.study_type,
    date_start: study.draft_data.date_start,
    study_id: study.id
  } : {
    ...clinicalReasons,
    ...insuranceInformation,
    ...study,
    date_start: study.date_start,
    device: (isMCT(study.study_type) || isTwoWeekHolter(study.study_type)) ? listDevicesMCT : listDevices?.[0]
  };

  const handleFinishStudy = async () => {
    await finishStudy(study.id);
  }

  const renderFinishStudyContent = () => {
    return (
        <Box p={3}>
          <Typography variant="h4" sx={{textAlign: "center", mb: 10}} paragraph={true}>
            Finish study
          </Typography>
          <Typography variant="body1" sx={{mb: 15, textAlign: "left"}}>
            To finish study, please press "Finish study".<br/><br/>
            Once, study is finished the following happens:<br/>
            - assigned device will be disconnected from the user.<br/>
            - data collection from particular device will be stopped
          </Typography>
          <Box sx={{textAlign: "center"}}>
            <MButton onClick={() => handleClose()} sx={{ml:2}}>Cancel finalization</MButton>

            <MButton color="error" onClick={() => handleFinishStudy()} sx={{ml:2}}>Finish study</MButton>
          </Box>
        </Box>
    );
  }

  const listActions = () => {
    let actions = [];
    // TODO : fix issue with passing color
    if (study?.status !== "completed"){
      actions.push({
        onClick: () => handleOpen(),
        name: "Finish study",
        //color: 'error'
      });
    }
    return actions;
}

  return (
    <>
      <MModal
          open={open}
          handleClose={handleClose}
          width={480}
          height="auto"
      >
        {renderFinishStudyContent()}
      </MModal>
      <PageHeader 
        title={patient ? formatName(patient.first_name, patient.last_name) : ''}
        subTitle={patient ? `${moment(patient.birthday).format('DD/MM/yyyy')}, ${capitalizeFirstLetter(patient.gender)}` : ''}
        actions={listActions()}
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            sx={{minHeight: '70vh'}}
          >
            {
              !isDraft(study) ? (
                <MTabs
                  value={activeTab}
                  onChange={(e, v) => setActiveTab(v)}
                  tabs={[
                    {
                      label: "Study Summary",
                      content: isRPM(study.study_type) ? (
                        <StudySummaryRPM
                          studyId={study.id}
                        />
                      ) : (
                        <StudySummary 
                          values={values}
                          devices={listDevices}
                          devicesMCT={listDevicesMCT}
                        />  
                      )
                    },
                    {
                      label: "Full Information",
                      content: (
                        <StudyForm 
                          edit 
                          values={values}
                        /> 
                      )
                    }
                  ]}
                />
              ) : (
                <StudyFormEditDraft
                  values={values}
                /> 
              )
            }
          </Paper>            
        </Grid>
      </Grid>
    </>
  );
};

export default Study;