import {useState, useEffect, useCallback} from "react";
import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {getClinicDetail, updateClinic} from "../api/clinic";
import {getClinicStaffList} from "../api/clinic_staff"
import {Box, Button, Drawer, Skeleton} from "@mui/material";
import {ClientAccountsGrid} from "../containers/ClientAccountsGrid";
import {MButton} from "../components/MButton";
import {baseUrls} from "../routes";
import ClinicianForm from "../containers/ClinicianForm";
import MModal from "../components/MModal";
import {analysisPlatforms, languages, studiesList} from "../utils/constants";
import MCheckbox from "../components/MCheckbox";
import * as React from "react";
import MTextField from "../components/MTextField";
import AdminMeasurementsGrid from "../containers/AdminMeasurementsGrid";
import {listStudiesAdmin, listStudiesClinicAdmin} from "../api/study";
import MSelect from "../components/MSelect";
import {listIdOrganizationExternals} from "../api/id_organization_external";



export const Clinic = () => {
    const { clinicId } = useParams();
    const navigate = useNavigate();

    const [studies, setStudies] = useState<any[]>([]);
    const [clinicName, setClinicName] = useState("");
    const [allowedStudies,setAllowedStudies] = useState<string[]>([]);
    const [studyLanguage,setStudyLanguage] = useState("");
    const [analysisPlatform, setAnalysisPlatform] = useState("amps");

    const [idOrgExternal, setIdOrgExternal] = useState("");
    const [idOrganizationExternals,setIdOrganizationExternals] = useState([]);



    const [isEditClinic, setIsEditClinic] = useState(false);

    const [staffProfiles, setStaffProfiles] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const loadContent = useCallback(async () => {
        if(clinicId){
            await fetchClinicDetail()
            await fetchStaffProfilesList()
            await fetchStudies()
        }
    },[clinicId])

    useEffect(() => {
        loadContent()
    }, [loadContent]);

    const fetchStudies = async () => {
        // setIsMeasurementAvailableMore(false);
        const studies = await listStudiesClinicAdmin(clinicId)

        let sortedDataDescending = studies.data.sort((a:any, b:any) =>
            new Date(b.date_start).getTime() - new Date(a.date_start).getTime()
        );
        setStudies(sortedDataDescending)
    }

    const fetchStaffProfilesList = async () => {
        if (clinicId != null) {
            const data = await getClinicStaffList(clinicId);
            setStaffProfiles(data);
            setIsLoaded(true);
            await fetchClinicDetail();
        }
    }

    const fetchClinicDetail =  async () => {
        if (clinicId != null) {
            const data = await getClinicDetail(clinicId);

            setClinicName(data['name']);
            setAllowedStudies(data['allowed_studies'])
            setStudyLanguage(data['study_language'])
            setAnalysisPlatform(data['analysis_platform'])
            setIdOrgExternal(data['id_organization_external'])

            const idOptions = await listIdOrganizationExternals();

            let options = idOptions.map((originalDict:any) => {
                return  {
                    value: originalDict.id_organization_external,
                    label: originalDict.id_organization_external+" - "+originalDict.description,
                };
            });

            setIdOrganizationExternals(options);


        }
    }

    const addAccount = () => {
        navigate(baseUrls.clinics+"/"+clinicId+"/add")
    }

    const saveAccountChange = async () =>{
        let data = {
            "name" : clinicName,
            "allowed_studies" :allowedStudies,
            "study_language": studyLanguage,
            "id_organization_external": idOrgExternal
        }
        await updateClinic(data,clinicId)
        setIsEditClinic(false);
    }

    const handleAllowedStudiesCheck = (event:any,key:string) => {
        var updatedList = [...allowedStudies];

        if (event.target.checked) {
            updatedList = [...allowedStudies, key];
        } else {
            updatedList.splice(allowedStudies.indexOf(key), 1);
        }
        setAllowedStudies(updatedList);
    };

    function renderEditClientButtons(){
        if(isEditClinic){
            return <>
                <Button color="error" onClick={()=>{
                    setIsEditClinic(false);
                    getClinicDetail(clinicId);
                    }
                }>
                    Cancel
                </Button>
                <Button  onClick={()=>{
                    setIsEditClinic(true);
                    saveAccountChange();
                }
                }>Save</Button>
            </>
        } else{
            return <>
                <Button onClick={()=>setIsEditClinic(true)}>Edit</Button>
            </>
        }
    }

    function renderAcccounts(){
        if (staffProfiles.length == 0 && !isLoaded){
            return <>
                <Box
                    justifyContent="center"
                    mb={5}
                >
                    <Skeleton variant="rectangular" width='100%' height={40} />
                    <br/>
                    <Skeleton variant="rectangular" width="100%" height={40} />
                </Box>
            </>

        }

        if(staffProfiles.length == 0 && isLoaded){

            return <Box justifyContent="center" display="flex" width="100%">
                <div>No data</div>
            </Box>
        }

        return  <>
            <ClientAccountsGrid data={staffProfiles} clinicId={clinicId}/>
        </>
    }


    return (
        <>
            <PageHeader title={`${!clinicName ? "??" : clinicName}`}
                        subTitle="clinic"
                        actions={[{onClick: addAccount, name: "Add Staff Account"}]}/>

            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                <Typography variant="h4" paragraph={true}>
                    Client details  {renderEditClientButtons()}
                </Typography>
                <Box display="flex">
                    <Box flex={1} p={1}>
                        <MTextField
                            label="Clinic name"
                            value={clinicName}
                            onChange={(e) => setClinicName(e.target.value)}
                            disabled={!isEditClinic}
                        />
                        <br/>
                        <br/>
                        <MSelect
                            label="Analysis platform"
                            disabled={!isEditClinic}
                            value={analysisPlatform}
                            options={analysisPlatforms}
                            onChange={(e) => {setAnalysisPlatform(e.target.value);}}
                        />
                        <br/>
                        <br/>
                        <MSelect
                            value={idOrgExternal}
                            disabled={!isEditClinic}
                            label="ID organization external"
                            options={idOrganizationExternals}
                            onChange={(e) => {setIdOrgExternal(e.target.value)}}
                        />

                    </Box>
                    <Box flex={1} p={1}>
                        <MSelect
                            label="Studies language"
                            value={studyLanguage}
                            disabled={!isEditClinic}
                            options={languages}
                            onChange={(e)=>setStudyLanguage(e.target.value)}
                        />
                        <br/>
                        <br/>
                        <Typography variant="body1" sx={{mt:1}}>
                            Allowed studies
                        </Typography>
                        {studiesList.map((item, index) => (
                            <>
                                <MCheckbox
                                    key={index}
                                    label={item.label}
                                    disabled={!isEditClinic}
                                    checked={allowedStudies.includes(item.value)}
                                    onChange={(e) => {handleAllowedStudiesCheck(e,item.value)}}
                                />
                                <br/>
                            </>
                        ))}
                    </Box>
                </Box>
                <br/>
                <br/>
                <Typography variant="h4" paragraph={true}>
                    Staff accounts
                </Typography>
                {renderAcccounts()}
                <br/>
                <br/>
                <Typography variant="h4" paragraph={true}>
                    Latest studies
                </Typography>
                <AdminMeasurementsGrid data={studies} />
            </Paper>
        </>
    );
};

export default Clinic;