import { useNavigate } from "react-router-dom";
import { MStudyTable, FieldType } from '../components/MStudyTable';
import {
    formatName,
    formatEnrollment,
    formatDateToShow,
    isDraft,
    formatStudyType,
    formatShortDescription
} from '../utils/utils';
import { baseUrls } from "../routes";
import MClientAccountsTable from "../components/MClientAccountsTable";
import MFirmwarePackagesTable from "../components/MFirmwarePackagesTable";
import MInfrastructuresTable from "../components/MInfrastructuresTable";

const tableHeadings = [
    {
        fieldName: 'Name',
        field: 'name'
    },
    {
        fieldName: 'Data service',
        field: 'data'
    },
    {
        fieldName: 'Dashboard service',
        field: 'dashboard'
    },
    {
        fieldName: 'Device service',
        field: 'device'
    },
    {
        fieldName: 'Users service',
        field: 'users'
    },
    {
        fieldName: 'Actions',
        field: 'actions'
    }

];

interface InfrastructuresGridProps {
    data: any[];
    // clientId?: string;
}

export const InfrastructuresGrid = (props: InfrastructuresGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row,
        // short_name: formatShortDescription(row.firmware_major,
        //     row.firmware_minor,
        //     row.firmware_build,
        //     row.hardware_revision),
        // description:row.description,
        // md5:row.md5
        id: row.id,
        name: row.name,
        data: row.data,
        device: row.device,
        users: row.users,
        dashboard: row.dashboard
    }));

    return (
        <MInfrastructuresTable
            headings={tableHeadings}
            rows={rows}

            // navigate={navigate}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
        />
    );
};

export default InfrastructuresGrid;