import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/system';
import * as React from "react";

import {useState} from "react";
import {Chip, Drawer} from "@mui/material";
import {baseUrls} from "../routes";
import {Link} from "react-router-dom";

export type FieldType = 'name' | 'departments_number' | 'staff_number' | 'patients_number';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface UnitsListTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

const formatMultipleValuesColumn = (i: number, value: string) => {
    if (i !== 0) {
        return <div>
            <br />
            {value}
        </div>;
    }

    return <div>{value}</div>;
};

export const UnitsListTable = (props: UnitsListTableProps) => {
    const { headings, rows, sx, onRowClick } = props;


    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            // case 'id':
            //     return <b>{row[heading.field]}</b>;
            case 'id':
                return <Link to={row.url}>{row[heading.field]}</Link>;
            case 'allowed_studies':
                return <>{row[heading.field].map((study_type:any,i:number) =>
                        formatMultipleValuesColumn(i,study_type))}</>

            default:
                return row[heading.field];
        }
    };

    return (
        <div>
            <TableContainer component={Paper} sx={sx}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }}>
                            {headings.map((heading: TableHeading, key: number) => (
                                <TableCell sx={{ fontSize: 16, fontWeight: 'bold' }} key={key}>{heading.fieldName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, key: number) => (
                            <TableRow key={key} sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }}
                                      onClick={() => {
                                      }}>
                                {headings.map((heading: TableHeading, key: number) => (
                                    <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                        {renderCell(heading, row) || '—'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default UnitsListTableProps;
