import React from "react";
import {Link, useNavigate} from "react-router-dom";

import { Box, Toolbar, Typography } from "@mui/material";

import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import MawiLogo from "../images/logo.svg";
import { border } from '../theme';
import {isAdmin, useAuth} from "../context/useAuth";
import { baseUrls } from "../routes";
import {MButton} from "../components/MButton";

interface SettingsMenuItem {
  name: string;
  onClick?: () => void;
}

const Navbar = () => {
  const { user, logout } = useAuth();
  const isAuthenticated = !!user;

  const settings: SettingsMenuItem[] = [ 
    // {
    //   name: 'Profile'
    // },
    // {
    //   name: 'Account'
    // },
    // {
    //   name: 'Dashboard'
    // },
    {
      name: 'Logout',
      onClick: logout
    }
  ];

  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingX: 2,
        ...border
      }}
    >
      <Toolbar 
        disableGutters
        style={{
          justifyContent: isAuthenticated ? "space-between" : "center"
        }}
      >
        <Link to={baseUrls.login}>
          <Typography
            variant="h6"
            noWrap
            sx={{ display: "flex" }}
          >
            <img src={MawiLogo} alt="Mawi Logo" />
          </Typography>
        </Link>

          {isAdmin(user) &&
              <Box display="flex" justifyContent="space-between">
                <MButton variant="text" onClick={() => navigate(baseUrls.clientsTools)}>
                  Studies/Devices
                </MButton>
                <MButton variant="text" sx={{"margin-left":10}} onClick={() => navigate(baseUrls.portalSettings)}>
                  Portal Settings
                </MButton>
              </Box>
        }
        {
          isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="test" src="https://cdn-icons-png.flaticon.com/512/147/147144.png" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting: SettingsMenuItem) => (
                  <MenuItem 
                    key={setting.name} 
                    onClick={() => {
                      setting.onClick && setting.onClick();
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )
        }
      </Toolbar>
    </Box>
  );
};

export default Navbar;
