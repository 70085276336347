import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SxProps, Theme } from '@mui/system';
import * as React from "react";

import {DeviceInfo} from "../containers/DeviceInfo";
import {useState} from "react";
import {Chip, Drawer} from "@mui/material";

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface AdminDevicesTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

export const AdminDevicesTable = (props: AdminDevicesTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const [selectedRow,setSelectedRow] = useState<any>(null);
    const [isModalOpen,setIsModalOpen] = useState<boolean>(false);

    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            // case 'id':
            //     return <b>{row[heading.field]}</b>;
            case 'connection':
                return (row[heading.field] !== null) ? <Chip label="Connected" color="primary" /> :
                    <Chip label="Available" />;

            default:
                return row[heading.field];
        }
    };

    return (
        <div>
            <Drawer
                anchor='right'
                open={isModalOpen}
                sx= {{ width: 480 }}
                PaperProps={{
                    sx: { width: "75%" },
                }}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedRow(null);
                }}
            >
                <DeviceInfo newItem={selectedRow}/>
            </Drawer>
            <TableContainer component={Paper} sx={sx}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }}>
                            {headings.map((heading: TableHeading, key: number) => (
                                <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, key: number) => (
                            <TableRow key={key} sx={{ cursor: !!onRowClick ? 'pointer' : 'default' }}
                                      onClick={() => {
                                          setSelectedRow(row);
                                          setIsModalOpen(true);
                                      }}>
                                {headings.map((heading: TableHeading, key: number) => (
                                    <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                        {renderCell(heading, row) || '—'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default AdminDevicesTable;
