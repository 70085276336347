import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MStatusPlates } from './MStatusPlates'
import * as React from "react";
import MModal from "./MModal";
import {DeviceInfo} from "../containers/DeviceInfo";
import {useState} from "react";
import {MeasurementInfo} from "../containers/MeasurementInfo";
import {Drawer, Typography} from "@mui/material";
import {baseUrls} from "../routes";
import MawiLogo from "../images/logo.svg";
import {Link} from "react-router-dom";

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface AdminMeasurementsTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

export const AdminStudiesTable = (props: AdminMeasurementsTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const formatMultipleValuesColumn = (i:number,value:string) => {
        return <>
            {i != 0 && <><br/><br/></>}
            {value}
        </>
    }
    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "status":
                return <MStatusPlates value={row[heading.field]} />
            case 'id':
                return <Link to={baseUrls.clientsTools+"/study/"+row[heading.field]}>{row[heading.field]}</Link>
            case 'date_start':
                return (row[heading.field] === null) ? null : new Date(row[heading.field]).toLocaleString();
            case 'date_upload':
                return <>{row.measurements.map((measurement:any,i:number) =>
                    formatMultipleValuesColumn(i,measurement.date_upload?
                        new Date(measurement.date_upload).toLocaleString() : "--"
                    ))}</>
            case 'date_finished':
                return (row[heading.field] === null) ? null : new Date(row[heading.field]).toLocaleString();
            case "device_serial_number":
                return <>{row.measurements.map((measurement:any,i:number) =>
                    formatMultipleValuesColumn(i,measurement.device_serial_number))}</>
            case 'report':
                return row.report ? (
                    <Box
                        sx={{
                            textDecoration: 'underline',
                            fontWeight: '600'
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(row.report, "_blank");
                        }}
                    >
                        View Final Report
                    </Box>
                ) : '—';
            default:
                return row[heading.field];
        }
    };

    return (
        <div>

        <TableContainer component={Paper} sx={sx}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headings.map((heading: TableHeading, key: number) => (
                            <TableCell sx={{ fontSize: 16 }} key={key}>{heading.fieldName}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row: any, key: number) => (
                        <TableRow key={key}>
                            {headings.map((heading: TableHeading, key: number) => (
                                <TableCell sx={{ fontSize: 16 }} component="th" scope="row" key={`${heading.field}_${key}`}>
                                    {renderCell(heading, row) || '—'}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
}

export default AdminStudiesTable;
