import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MTextField from './MTextField';
import {useState} from "react";

interface MDatePickerProps {
  label?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const MDatePicker = (props: MDatePickerProps) => {
  const { label, value = null, onChange, disabled, error, helperText } = props;
  const [open,setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {label && <InputLabel>{label}</InputLabel>}
      <DesktopDatePicker
        open={open}
        inputFormat="dd-MM-yyyy"
        value={value}
        onChange={onChange}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        // onFocus={(e:any) => e.target.blur()}
        closeOnSelect={true}
        // InputProps={{readOnly: true}}
        InputProps={{ onKeyDown: e => e.preventDefault() }}
        renderInput={(params) => <MTextField {...params}
                                             onClick={(e) => setOpen(true)}
                                             label=""
                                             error={error}
                                             helperText={helperText}

        />}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}

export default MDatePicker;
