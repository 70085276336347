import moment from "moment";
import { Enrollment, StudyType } from '../api/study';
import * as React from "react";

export const emptyValueFormatter = (value: string) => {
  if (!value) return undefined;

  return value;
}

export const formatName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`;

export const formatEnrollment = (enrollment: Enrollment) => {
  if (enrollment.toLowerCase() === 'home') {
    return 'At home';
  }

  if (enrollment.toLowerCase() === 'clinic') {
    return 'In-clinic';
  }

  return '';
}

export const formatStudyType = (studyType: StudyType) => {
  if (studyType === 'holter') {
    return 'Holter';
  }

  if (studyType === 'mct') {
    return 'MCT'
  }

  if (studyType === 'two_week_holter') {
    return "2-week Holter(Up to 14 days)";
  }

  if (studyType === 'rpm') {
    return 'RPM'
  }

  return '';
}

export const formatDateAPI = (date : Date | null) => date ? moment(date).format("D-M-YYYY") : null;

export const formatDateToShow = (date: Date | string | null) => moment(date).format('ll');
export const formatDateTimeToShow = (date: Date | string) => moment(date).format('lll');
export const formatTimeToShow = (date: Date | string) => moment(date).format('HH:mm');

export const dateFromString = (dateStr:string) =>{return moment(dateStr, 'HH:mm').toDate()}

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isObject = (value: any) => typeof value === 'object' && value !== null;

export const isDraft = (data: any) => isObject(data.draft_data) && data.status === 'draft';

export const isHolter = (value: string) => value.toLowerCase() === 'holter';
export const isMCT = (value: string) => value.toLowerCase() === 'mct';

export const isTwoWeekHolter = (value: string) => value.toLowerCase() === 'two_week_holter';

export const isRPM = (value: string) => value.toLowerCase() === 'rpm';

export const datesDifference = (oldDate: Date) => {
  if(oldDate === null){
    return "Never"
  }

  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  let currentDate = new Date();

  let diff = currentDate.getTime() - oldDate.getTime()

  if(diff > 30*24*60*60*1000){
    return formatter.format(Math.round(-diff / (30*24*60*60*1000)), 'months');
  } else if(diff > 24*60*60*1000){
    return formatter.format(Math.round(-diff / (24*60*60*1000)), 'days');
  } else if(diff > 60*60*1000){
    return formatter.format(Math.round(-diff / (60*60*1000)), 'hours');
  } else if(diff > 60*1000){
    return formatter.format(Math.round(-diff / (60*1000)), 'minutes');
  } else{
    return formatter.format(Math.round(-diff / 1000), 'seconds');
  }
}

export const formatShortDescription = (major:number,minor:number,build:number,hardware:string) => {
  return major + "." + minor +"." + build + "-"+hardware
}

export const shortenString = (value: string) => {
  if(value.length > 50){
    return value.substring(0,50) + "...";
  }
  return value;
}