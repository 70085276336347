import { useNavigate } from "react-router-dom";

import MIdOrganizationExternalsTable from "../components/MIdOrganizationExternalsTable";
import {formatDateToShow} from "../utils/utils";

const tableHeadings = [
    {
        fieldName: 'ID organization external',
        field: 'id_organization_external'
    },
    {
        fieldName: 'Date created',
        field: 'created_at'
    },
    {
        fieldName: 'Description',
        field: 'description'
    },
    {
        fieldName: 'Actions',
        field: 'actions'
    }

];

interface IDOrganizationExternalsGridProps {
    data: any[];
    // clientId?: string;
}

export const IDOrganizationExternalsGrid = (props: IDOrganizationExternalsGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row,
        description:row.description,
        id_organization_external:row.id_organization_external,
        created_at:formatDateToShow(row.created_at)
    }));

    return (
        <MIdOrganizationExternalsTable
            headings={tableHeadings}
            rows={rows}

            // navigate={navigate}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
        />
    );
};

export default IDOrganizationExternalsGrid;