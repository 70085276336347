import { ReactElement } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useAuth, isClinician, isTechnician, isAdmin } from "./context/useAuth";

// pages
import Login from "./pages/Login";
import ClinicianHome from "./pages/ClinicianHome";
import AddStudies from "./pages/AddStudy";
import Study from "./pages/Study";
import Reports from "./pages/Reports";
import ReportView from "./pages/ReportView";
import ReportReview from "./pages/ReportReview";
import Region from "./pages/Region";
import Clinic from "./pages/Clinic";
import AdminSearch from "./pages/AdminSearch";
import ClinicAddAccount from "./pages/ClinicAddAccount";
import {PortalSettings} from "./pages/PortalSettings";
import {AdminStudy} from "./pages/AdminStudy";
import Regions from "./pages/Regions";
import Cluster from "./pages/Cluster";

interface ProtectedRouteInterfaces {
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactElement;
}

export const baseUrls = {
    login: '/',
    studies: '/studies',
    studiesAdd: '/studies/add',
    study: '/study',
    studyPage: '/study/:studyId',
    reports: '/reports',
    reportView: '/reports/view',
    reportReview: '/report/review',


    regions: '/regions',
    region: '/regions/:regionId',

    clusters: '/clusters',
    cluster: '/clusters/:clusterId',

    clinics: '/clinics',
    clinic: '/clinics/:clinicId',
    clinicAddAccount :  '/clinics/:clinicId/add',
    clinicsEditAccount :  '/clinics/:clinicId/edit/:staffProfileId',


    // clientsAdd: '/clients/add',
    // clientsAccount: '/clients/:clientId',

    // clientsEditAccount :  '/clients/:clientId/edit/:clientAccountID',
    clientsTools : "/clients/tools",
    clientsToolsStudy : "/clients/tools/study/:idStudy",
    portalSettings: "/clients/settings"
}

export const ProtectedRoute = ({ isAllowed, redirectPath = baseUrls.login, children }: ProtectedRouteInterfaces) => {
  if (!isAllowed) {
    // user is not authenticated
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

export const AppRoutes = () => {
  const { user } = useAuth();

  return(
    <Routes>
      <Route index element={<Login />} />
      <Route element={<ProtectedRoute isAllowed={!!isClinician(user)} />}>
        <Route path={baseUrls.studies} element={<ClinicianHome />} />
        <Route path={baseUrls.studiesAdd} element={<AddStudies />} />
        <Route path={baseUrls.studyPage} element={<Study />} />
      </Route>
      {/*<Route element={<ProtectedRoute isAllowed={!!isTechnician(user)} />}>*/}
      {/*  <Route path={baseUrls.reports} element={<Reports />} />*/}
      {/*  <Route path={baseUrls.reportView} element={<ReportView />} />*/}
      {/*  <Route path={baseUrls.reportReview} element={<ReportReview />} />*/}
      {/*</Route>*/}
      <Route element={<ProtectedRoute isAllowed={!!isAdmin(user)} />}>
          <Route path={baseUrls.regions} element={<Regions />} />
          <Route path={baseUrls.region} element={<Region />} />
          <Route path={baseUrls.cluster} element={<Cluster />} />
          <Route path={baseUrls.clinic} element={<Clinic />}/>


        {/*<Route path={baseUrls.clientsAccount} element={<Clinic />}/>*/}
        <Route path={baseUrls.clinicAddAccount} element={<ClinicAddAccount action="create"/>}/>
        <Route path={baseUrls.clinicsEditAccount} element={<ClinicAddAccount action="edit"/>}/>
        <Route path={baseUrls.clientsTools} element={<AdminSearch />}/>
        <Route path={baseUrls.portalSettings} element={<PortalSettings />}/>
        <Route path={baseUrls.clientsToolsStudy} element={<AdminStudy />}/>

      </Route>
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  )
};