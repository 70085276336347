import { useNavigate } from "react-router-dom";
import {formatName, formatEnrollment, formatDateToShow, isDraft, formatStudyType, shortenString} from '../utils/utils';
import { baseUrls } from "../routes";
import {UnitsListTable} from "../components/UnitsListTable";

const tableHeadingsRegion = [
    {
        fieldName: 'ID',
        field: 'id'
    },
    {
        fieldName: 'Region Name',
        field: 'name'
    },
    {
        fieldName: 'Units number',
        field: 'departments_number'
    },
    {
        fieldName: 'Staff number',
        field: 'staff_number'
    },
    {
        fieldName: 'Patients number',
        field: 'patients_number'
    }
];


const tableHeadingsCluster = [
    {
        fieldName: 'ID',
        field: 'id'
    },
    {
        fieldName: 'Cluster Name',
        field: 'name'
    },
    {
        fieldName: 'Units number',
        field: 'departments_number'
    },
    {
        fieldName: 'Staff number',
        field: 'staff_number'
    },
    {
        fieldName: 'Patients number',
        field: 'patients_number'
    }
];

const tableHeadingsClinic = [
    {
        fieldName: 'ID',
        field: 'id'
    },
    {
        fieldName: 'Clinic Name',
        field: 'name'
    },
    {
        fieldName: 'Staff number',
        field: 'staff_number'
    },
    {
        fieldName: 'Patients number',
        field: 'patients_number'
    },
    {
        fieldName: 'Allowed studies',
        field: 'allowed_studies'
    },
    {
        fieldName: 'Analysis Platform',
        field: 'analysis_platform'
    },
    {
        fieldName: 'ID Org External',
        field: 'id_organization_external'
    }

];


interface UnitsListGridProps {
    data: any[];
    onReportCellClick?: (url: string) => void;

    type : "region" | "cluster" | "clinic"
}

function getTableHeadings(type:string){
    if(type == "region"){
        return tableHeadingsRegion;
    } else if (type == "cluster"){
        return tableHeadingsCluster;
    }
    return tableHeadingsClinic;
}

function getUrl(row:any, type:string){
    if(type == "region"){
        return baseUrls.regions+"/"+row.id
    }

    if(type == "cluster"){
        return baseUrls.clusters + "/" + row.id
    }

    return baseUrls.clinics + "/" + row.id;
}

export const UnitsListGrid = (props: UnitsListGridProps) => {
    const navigate = useNavigate();

    const { data,type } = props;

    const rows = data.map((row) => ({
        ...row,
        id: row.id,
        name:shortenString(row.name),
        departments_number:row.departments_number,
        staff_number:row.staff_number,
        patients_number:row.patients_number,
        url: getUrl(row,type),
        allowed_studies: row.allowed_studies,
        analysis_platform: row.analysis_platform,
        id_organization_external: row.id_organization_external
    }));

    return (
        <UnitsListTable
            headings={getTableHeadings(type)}
            rows={rows}
        />
    );
};

export default UnitsListGrid;