import InputLabel from '@mui/material/InputLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MTextField from './MTextField';
import React, {useState} from "react";
import {DesktopDateTimePicker} from "@mui/x-date-pickers";

interface MDateTimePickerProps {
  label?: string;
  value?: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  variant? : "outlined" | "filled" |  "standard";
}

export const MDateTimePicker = (props: MDateTimePickerProps) => {
  const { label, value = null, onChange, disabled, error, helperText,variant="outlined" } = props;
  const [open,setOpen] = useState(false);


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {label && <InputLabel>{label}</InputLabel>}
      <DesktopDateTimePicker
          ampm={false}
          ampmInClock={false}
          open={open}
          value={value}
          closeOnSelect={true}
          views={['year', 'day', 'hours', 'minutes']}

          onChange={onChange}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => <MTextField {...params}
                                               onClick={(e) => setOpen(true)}
                                               variant={variant}
                                               label=""
                                               disabled={disabled}
                                               error={error}
                                               helperText={helperText} />}
          disabled={disabled}
        />
    </LocalizationProvider>
  );
}

export default MDateTimePicker;
